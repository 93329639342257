import React, { useCallback, useMemo } from 'react';
import {
    SettingsOrderNoAddressTitle,
    SettingsOrderPricesContainer,
    SettingsOrderPurchaseStopPriceNoAddressContent
} from './style';
import SettingsOrdersRow from '../SettingsOrderRow';
import { Translate } from '../../../../../internationalization/translate';
import { useSelector } from 'react-redux';
import {
    selectSettingsOrderDistanceStartCalculation,
    selectSettingsOrderPurchaseStopPriceNoAdd,
    selectSettingsOrderStopPriceNoAdd
} from '../../../../../store/app/appSlice';
import { formatNumber, formatPrice } from '../../../../../utils';
import SettingsOrderPurchaseStopPriceNoAddress from './SettingsOrderPurchaseStopPriceNoAddress';
import Translation from '../../../../../components/Translation';
import { useModal } from '../../../../../hooks/useModal';
import ChangeOrderStopPriceNoAddress from '../../Dialogs/ChangeOrderStopPriceNoAddress';
import ChangeOrderPurchaseStopPriceNoAddress from '../../Dialogs/ChangeOrderPurchaseStopPriceNoAddress';
import ChangeOrderDistanceStartCalculation from '../../Dialogs/ChangeOrderDistanceStartCalculation';

const SettingsOrderPrices = () => {

    const settingsOrderStopPriceNoAdd = useSelector(selectSettingsOrderStopPriceNoAdd);
    const settingsOrderPurchaseStopPriceNoAdd = useSelector(selectSettingsOrderPurchaseStopPriceNoAdd);
    const settingsOrderDistanceStartCalculation = useSelector(selectSettingsOrderDistanceStartCalculation);

    const data = useMemo(() => {
        if (!settingsOrderPurchaseStopPriceNoAdd) return [];
        return Object.keys(settingsOrderPurchaseStopPriceNoAdd)?.reduce((acc: any, x: any) => {
            const value = settingsOrderPurchaseStopPriceNoAdd[x];
            return [
                ...acc,
                {
                    id: x,
                    range: `${formatNumber(value.from)}${value.to ? ` - ${formatNumber(value.to)}` : ' - ∞'}`,
                    price: formatPrice(value.price),
                    from: value.from,
                    to: value.to
                }
            ];
        }, []);
    }, [settingsOrderPurchaseStopPriceNoAdd]);


    const { openModal: openModalChangeStopPrice, closeModal: closeChangeStopPriceModal } = useModal({
        modal: ChangeOrderStopPriceNoAddress,
        isActionsButtonsVisible: false,
        isCloseAction: false,
        noPadding: true
    });


    const { openModal: openModalChangePurchaseStopPrice, closeModal: closeChangePurchaseStopPriceModal } = useModal({
        modal: ChangeOrderPurchaseStopPriceNoAddress,
        isActionsButtonsVisible: false,
        isCloseAction: false,
        noPadding: true
    });

    const { openModal: openModalDistanceStartCalculation, closeModal: closeChangeDistanceStartCalculationModal } = useModal({
        modal: ChangeOrderDistanceStartCalculation,
        isActionsButtonsVisible: false,
        isCloseAction: false,
        noPadding: true
    });

    const handlerEditDistanceStartCalculation = useCallback(()=> {
        openModalDistanceStartCalculation({
            closeModal: closeChangeDistanceStartCalculationModal
        });
    },[openModalDistanceStartCalculation, closeChangeDistanceStartCalculationModal]);

    const handlerEditStopPriceNoAddress = useCallback(()=> {
        openModalChangeStopPrice({
            closeModal: closeChangeStopPriceModal
        });
    },[openModalChangeStopPrice, closeChangeStopPriceModal]);

    const handlerEditPurchaseStopPriceNoAddress = useCallback(()=> {
        openModalChangePurchaseStopPrice({
            closeModal: closeChangePurchaseStopPriceModal
        });
    },[openModalChangePurchaseStopPrice, closeChangePurchaseStopPriceModal]);

    return (
        <SettingsOrderPricesContainer>
            <Translation use={Translate.settingsOrderNoAddress} as={SettingsOrderNoAddressTitle} />
            <SettingsOrdersRow label={Translate.settingsOrderStopPriceNoAddress} value={formatPrice(settingsOrderStopPriceNoAdd)} handleAction={handlerEditStopPriceNoAddress} />
            <SettingsOrdersRow label={Translate.settingsOrderPurchaseStopPriceNoAddress} handleAction={handlerEditPurchaseStopPriceNoAddress} isButtonAbsolute>
                <SettingsOrderPurchaseStopPriceNoAddressContent>
                    {data.map((x: any) => <SettingsOrderPurchaseStopPriceNoAddress {...x} key={x.id} />)}
                </SettingsOrderPurchaseStopPriceNoAddressContent>
            </SettingsOrdersRow>
            <SettingsOrdersRow label={Translate.settingsOrderDistanceStartCalculation} value={settingsOrderDistanceStartCalculation} handleAction={handlerEditDistanceStartCalculation} />
        </SettingsOrderPricesContainer>
    );
};

export default SettingsOrderPrices;