import {useMutation} from '@tanstack/react-query';
import axiosClient from 'core/api/config/axios';
import {createUrl} from 'core/api/utils/createUrl';

const useProcurementReviewMutation = () => {
    const mutation = useMutation({
        mutationFn: async (id: number|string) => {
            const response = await axiosClient.patch(createUrl({
                url: `/dispatcher/order-procurement/review/${id}`,
                version: 'v1',
            }));
            return response.data;
        }
    });
    return mutation;
};

export default useProcurementReviewMutation;
