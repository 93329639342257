import React, { useCallback } from 'react';
import { OrderProcurementButtonBadge, OrderProcurementButtonIcon } from './style';
import OrderProcurementList from './OrderProcurementList';
import { Colors } from '../../../../theme/palette';
import { Menu } from '@mui/material';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import { useTranslation } from 'react-i18next';
import Translation from '../../../../components/Translation';
import { Translate } from '../../../../internationalization/translate';
import useGetProcurementCount from '../../../../core/api/hooks/query/procurement/useGetProcurementsCount';


const OrderProcurement = ({ handleOpenOrder }: any) => {
    const { t } = useTranslation();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const { data, refetch } = useGetProcurementCount();


    const handleClick = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    }, [setAnchorEl]);

    const handleClose = useCallback((_: any, reason: string) => {
        if (reason === 'backdropClick' || reason === 'escapeKeyDown') return;
        setAnchorEl(null);
    }, [setAnchorEl]);



    return (
        <>
            {data ? <OrderProcurementButtonBadge badgeContent={<>{data}</>}>
                <OrderProcurementButtonIcon
                    aria-controls={open ? 'procurements' : undefined}
                    onClick={handleClick}
                    startIcon={<ShoppingBasketIcon />}
                    title={t(Translate.orderProcurementButtonTitle)}
                >
                    <Translation use={Translate.orderProcurementButtonText} />
                </OrderProcurementButtonIcon>
            </OrderProcurementButtonBadge> : (
                <OrderProcurementButtonIcon
                    aria-controls={open ? 'procurements' : undefined}
                    onClick={handleClick}
                    startIcon={<ShoppingBasketIcon />}
                    disabled={!data?.length}
                    title={t(Translate.orderProcurementButtonTitle)}
                >
                    <Translation use={Translate.orderProcurementButtonText} />
                </OrderProcurementButtonIcon>)}
            <Menu
                id="procurement-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                    sx: {
                        padding: 0
                    }
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        minWidth: 520,
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        border: `1px solid ${Colors.gradient.yellowLight}`,
                        borderRadius: '8px'
                    }
                }}
            >
                <OrderProcurementList onClose={handleClose} setRefetchCount={refetch}
                                      handleOpenOrder={handleOpenOrder} />
            </Menu>
        </>
    );
};

export default OrderProcurement;