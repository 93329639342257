import React, { useCallback, useContext } from 'react';
import useModalFormData from '../../../../../../../hooks/useModalFormData';
import { PreviewOrderItemRemoveItemContainer } from './style';
import { Trans, useTranslation } from 'react-i18next';
import { Translate } from '../../../../../../../internationalization/translate';
import UiDataContext from '../../../../../../../context/UiContext/context';
import useRemoveOrderItemMutation from '../../../../../../../core/api/hooks/mutations/order/useRemoveOrderItemMutation';
import { errorToast, successToast } from '../../../../../../../components/Utils/Toast';
import { queryClient } from '../../../../../../../core/root/ReactQueryProvider';


const PreviewOrderItemRemoveItem = ({ item, number, closeDialog }: any) => {
    const {t} = useTranslation();
    const { setLoading, resetLoading } = useContext(UiDataContext);
    const removeOrderItemMutation = useRemoveOrderItemMutation();

    const handlerOnSubmit = useCallback(async () => {
        if (!item || !item.id) return true;
        try {
            setLoading();
            const result = await removeOrderItemMutation.mutateAsync({
                id: item.id
            });
            if (result?.status === 200) {
                successToast(t(result.message));
                await queryClient.invalidateQueries({queryKey: [`get-order-${item.orderFreeId}`]});
                await queryClient.invalidateQueries({queryKey: ['get-orders']});
                closeDialog();
            }
        }catch (e: any) {
            errorToast(t(e?.response?.data?.key || e?.response?.data?.message));
        }finally {
            resetLoading();
        }
        return false;
    }, [item, closeDialog,  setLoading, resetLoading, removeOrderItemMutation]);

    useModalFormData({
        modalTitle: 'orderPreviewItemsRemoveModalTitle',
        handlerOnSubmit
    });

    return (
        <PreviewOrderItemRemoveItemContainer>
            <Trans
                i18nKey={Translate.orderPreviewItemRemoveModalText}
                values={{
                    address: item.address,
                    number
                }}
                components={{
                    b: <b></b>,
                    i: <i></i>
                }}
            />
        </PreviewOrderItemRemoveItemContainer>
    );
};

export default PreviewOrderItemRemoveItem;