import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../index';
import { omit } from 'lodash';


interface OrderState {
    images: any[];
    selectedImages: any;
    orderProcurement?: any;
}

const initialState: OrderState = {
    selectedImages: {},
    images: []
};


export const orderSlice = createSlice({
    name: 'order',
    initialState,
    reducers: {
        selectOrderProcurementImage: (state, action) => {
            const { image, itemId, itemNumber } = action.payload;
            if (!image || !itemId) return;
            const rootImages = [...state.images];
            const rootIndex = rootImages.findIndex(img => img.id === image.id);
            if (rootIndex !== -1) {
                const isAlreadySelected = rootImages[rootIndex].selectedBy == itemId;
                rootImages.splice(rootIndex, 1, Object.assign({ ...rootImages[rootIndex] }, !isAlreadySelected ? {
                    selectedBy: itemId,
                    selectedByNumber: itemNumber
                } : { selectedBy: undefined, selectedByNumber: undefined }));
                state.images = [...rootImages];
            }
            const images = [...(state?.selectedImages?.[itemId] || [])];
            const indx = images.findIndex(img => img.id === image.id);
            if (indx !== -1) images.splice(indx, 1);
            else images.push(image);
            state.selectedImages = {
                ...(state?.selectedImages),
                [itemId]: images
            };
        },
        setOrderProcurementSelectedImages: (state, action: PayloadAction<any>) => {
            state.selectedImages = {
                ...action.payload
            };
        },
        setOrderProcurementImages: (state, action) => {
            state.images = [...action.payload];
        },
        setOrderProcurement: (state, action: PayloadAction<any>) => {
            state.orderProcurement = action.payload;
        },
        clearOrderProcurement: (state) => {
            state.orderProcurement = undefined;
            state.selectedImages = {};
            state.images = [];
        },
        removeSelectedItem: (state, action: PayloadAction<any>) => {
            const itemId = action.payload;
            if (!itemId) return;
            const rootImages = [...state.images];
            const rootIndex = rootImages.findIndex(img => img.selectedBy === itemId);
            if (rootIndex !== -1) rootImages.splice(rootIndex, 1, { ...rootImages[rootIndex], selectedBy: undefined, selectedByNumber: undefined });
            state.images = [...rootImages];
            state.selectedImages = { ...(omit(state?.selectedImages, itemId)) };
        },
        selectAllImages: (state, action: PayloadAction<any>) => {
            const {  itemId, itemNumber } = action.payload;
            if (!itemId || !itemNumber) return;
            state.images = [...state.images].map(x=> ({
                ...x,
                selectedBy: itemId,
                selectedByNumber: itemNumber
            }));
            state.selectedImages = state.selectedImages = {
                ...(state?.selectedImages),
                [itemId]: [...state.images]
            };
        },
        unSelectAll: (state, action: PayloadAction<any>) => {
            const { itemId } = action.payload;
            if (!itemId) return;
            state.images = [...state.images].map(x=> ({
                ...x,
                selectedBy: undefined,
                selectedByNumber: undefined
            }));
            state.selectedImages = { ...(omit(state?.selectedImages, itemId)) };
        }
    }
});

export const {
    selectOrderProcurementImage,
    setOrderProcurementImages,
    setOrderProcurement,
    clearOrderProcurement,
    setOrderProcurementSelectedImages,
    removeSelectedItem,
    selectAllImages,
    unSelectAll
} = orderSlice.actions;

export const selectOrderImages = (state: RootState) => state.order.images;
export const selectOrderSelectedImages = (state: RootState) => state.order.selectedImages;
export const selectOrderProcurement = (state: RootState) => state.order.orderProcurement;
export const selectIsAllSelected = (state: RootState) => state.order.images.filter((x) => !x.selectedBy).length <= 0;

export default orderSlice.reducer;
