import React, { FC, useMemo, useCallback } from 'react';
import {
    OrderProcurementListActionButton,
    OrderProcurementListItemActions,
    OrderProcurementListItemContainer,
    OrderProcurementListItemContent,
    OrderProcurementListItemContentRow,
    OrderProcurementListItemContentSubText,
    OrderProcurementListItemContentTitle,
    OrderProcurementListItemRightPart
} from './style';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import Translation from '../../../../../components/Translation';
import DrawIcon from '@mui/icons-material/Draw';
import { useDispatch } from 'react-redux';
import { setOrderProcurement } from '../../../../../store/order/orderSlice';
import { Translate } from '../../../../../internationalization/translate';
import moment from 'moment';
import { Colors } from '../../../../../theme/palette';
import { useModal } from '../../../../../hooks/useModal';
import OrderProcurementAbort from '../../modals/OrderProcurementAbort';
import useProcurementReviewMutation
    from '../../../../../core/api/hooks/mutations/procurement/useProcurementReviewMutation';


const OrderProcurementListItem: FC<any> = ({ item, handleOpenOrder }) => {

    const dispatch =  useDispatch();
    const procurementReviewMutation = useProcurementReviewMutation();
    const [procurementNumber, procurementDate, address, buildingNumber, createdAt] = useMemo(()=> ([
       item.procurementNumber || '',
       moment(item.procurementDate).format('DD.MM.YYYY') || '',
       item.address || '',
       item?.buildingNumber || '',
       moment(item.createdAt).format('DD.MM.YYYY HH:mm'),
    ]), [item]);

    const handleGenerateOrder = useCallback(async () => {
        handleOpenOrder();
        dispatch(setOrderProcurement(item));
        try {
            await procurementReviewMutation.mutateAsync(item.id);
        }catch (e) {
            console.log(e);
        }
    }, [item, handleOpenOrder, dispatch, procurementReviewMutation]);


    const { openModal: openDialogProcurementAbort, closeModal: closeDialogProcurementAbort } = useModal({
        modal: OrderProcurementAbort,
        isActionsButtonsVisible: true,
        isCloseAction: true,
        noPadding: true
    });
    

    const handleAbortProcurement = useCallback(() => {
        if(!item?.id) return;
        openDialogProcurementAbort({
            procurement: item,
            closeDialog: closeDialogProcurementAbort
        });
    }, [item, openDialogProcurementAbort, closeDialogProcurementAbort]);

    return (
        <OrderProcurementListItemContainer>
            <OrderProcurementListItemContentRow>
                <OrderProcurementListItemContent>
                    <Translation use={Translate.orderProcurementMenuItemTitle} as={OrderProcurementListItemContentTitle} params={{procurementNumber, procurementDate}}/>
                    <Translation use={Translate.orderProcurementMenuItemText} as={OrderProcurementListItemContentSubText} params={{address}}/>
                    { buildingNumber && <Translation use={Translate.orderProcurementMenuItemBuildingNumberText} as={OrderProcurementListItemContentSubText} params={{buildingNumber}} />}
                    <Translation use={Translate.orderProcurementMenuItemDateText} as={OrderProcurementListItemContentSubText} params={{createdAt}}/>
                </OrderProcurementListItemContent>
            </OrderProcurementListItemContentRow>
            <OrderProcurementListItemRightPart>
                <OrderProcurementListItemActions>
                    <OrderProcurementListActionButton title={Translate.letStart} onClick={handleGenerateOrder} >
                        <DrawIcon />
                    </OrderProcurementListActionButton>
                    <OrderProcurementListActionButton title={Translate.abort} onClick={handleAbortProcurement} color={Colors.red}>
                        <HighlightOffIcon />
                    </OrderProcurementListActionButton>
                </OrderProcurementListItemActions>
            </OrderProcurementListItemRightPart>
        </OrderProcurementListItemContainer>
    );
};

export default OrderProcurementListItem;