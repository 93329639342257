import styled, { css } from 'styled-components';
import { Flex } from '../../../../../../assets/style';
import { Colors } from '../../../../../../theme/palette';


export const OrderProcurementImagesListItemImage = styled.img`
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    z-index: 0;
`;

export const OrderProcurementImagesListItemContainer = styled(Flex).withConfig({
    shouldForwardProp: (prop) => !['isSelected', 'selectAllow'].includes(prop),
})<{isSelected?: boolean, selectAllow?: boolean}>`
    border-radius: 10px;
    position: relative;
    min-height: 100px;
    max-height: 100px;
    width: 100px;
    z-index: 10;
    font-family: Readex Pro;
    justify-content: center;
    align-items: center;\
    cursor: pointer;
    
    ${props => (props?.isSelected || !props?.selectAllow) && css`
        border: 1px solid ${Colors.gradient.yellowDark};
        ${OrderProcurementImagesListItemImage}{
            opacity: 0.5;
        }
    `}
    
    ${props => !props?.selectAllow && css`
        cursor: not-allowed;
    `}
`;






export const OrderProcurementRelateImagesListItemCheckbox = styled(Flex)`
    width: 20px;
    height: 20px;
    top: 5px;
    right: 5px;
    border: 1px solid ${Colors.gradient.yellowDark};
    z-index: 10;
    background: black;
    background: ${Colors.black};
    border-radius: 100%;
    padding: 5px;
    position: absolute;
    justify-content: center;
    align-items: center;
    
    svg {
         color: ${Colors.white};   
    }
`;


export const OrderProcurementImagesListItemSelectedByContainer = styled(Flex)`
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    z-index: 10;
    padding: 5px;
    position: absolute;
    justify-content: center;
    align-items: center;
`;

export const OrderProcurementImagesListItemSelectedByNumber = styled(Flex)`
   z-index: 100;
   color: ${Colors.gradient.yellowLight};
   text-shadow: 1px 1px 1px ${Colors.black};
   font-size: 32px;
   line-height: 28px;
   font-weight: bolder;
`;

