import styled from 'styled-components';
import { ListItemIcon, Menu, MenuItem } from '@mui/material';
import { Colors } from '../../../../../../../theme/palette';
import ButtonIcon from '../../../../../../../components/button/ButtonIcon';

export const ShiftActionButton = styled(ButtonIcon)`
    &&& {
        width: 25px;
        height: 25px;
        position: absolute;
        top: 5px;
        right: 5px;
        color: ${Colors.white} !important;
    }
`;

export const ShiftStartedActionMenuItem = styled(MenuItem)`
  &&& {
    color: #000000;
    padding: 8px 10px;
     
     &.MuiMenuItem-divider {
        border-bottom-color: #000000;
     }
  }
`;

export const ShiftStartedActionMenuItemIcon = styled(ListItemIcon)`
  &&& {
    color: #000000;
  }
`;


export const ShiftStartedActionMenu =  styled(Menu)`
    &&& {
        .MuiPaper-root {
            min-width: 140px;
            max-width: 180px;
            background-color: #FFFFFF;
            border: 1px solid #000000;
            border-radius: 10px;

            .MuiList-root {
                padding: 0;
            }
        }
    }
`;
