import { useQuery } from '@tanstack/react-query';
import axiosClient from '../../../config/axios';
import { createUrl } from '../../../utils/createUrl';

const useGetProcurement = (id: number) => {
    const { data, isLoading, isFetched, isFetching, refetch } = useQuery({
        queryKey: [`get-procurement-${id}`],
        queryFn: async () => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const res: any = await axiosClient.get(createUrl({
                url: `/dispatcher/order-procurement/${id}`,
                version: 'v1'
            }));
            return res.data?.data || [];
        },
        enabled: !!id
    });
    return { data, isLoading, isFetched, isFetching, refetch };
};

export default useGetProcurement;