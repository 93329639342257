import styled from 'styled-components';
import Button from '../../../../components/button';
import { Badge } from '@mui/material';


export const OrderProcurementButtonIcon = styled(Button)`
    &&& {
        display: flex;
        justify-content: space-between;
        min-width: 85px;
        max-width: 190px;
        gap: 5px;
        padding: 5px 10px;
        color: black;
        border-radius: 10px;
        font-family: Readex Pro;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

        .MuiButton-startIcon {
            margin-right: 0;
            width: 24px;
            height: 24px;
        }
    }
`;

export const OrderProcurementButtonBadge = styled(Badge)`
  &&& {
     .MuiBadge-badge {
       background-color: #11D600;
       color: #FFFFFF;
     }
  }
`;
