import { ORDER_TYPES } from '../../../../d';


export enum ORDER_FREE_EVENTS {
    ADD_NEW_ITEM = 'ADD_NEW_ITEM',
    REMOVE_ITEM = 'REMOVE_ITEM',
    RESET_ITEMS = 'RESET_ITEMS',
    UPDATE_ITEM = 'UPDATE_ITEM',
    SET_DATA = 'SET_DATA',
    SAVE_DATA = 'SAVE_DATA',
    RESET_STATE = 'RESET_STATE',
    SET_IS_FROM_STORAGE = 'SET_IS_FROM_STORAGE'
}

export type TOrderFreeActions = {
    type: ORDER_FREE_EVENTS,
    payload?: any
}
export type TOrderItemRecord = {
    id?: number
    description: string,
    address: string,
    pickUpValue: number|string,
    amount?: number|string
}


export type TOrderFreeReducer = {
    items: TOrderItemRecord[] | any[]
}

export const initialOrderItemRecord = {
    description: '',
    address: '',
    pickUpValue: '',
    amount: ''
};

export const initialOrderFree = {
    items: [
        {
            id: 1720895846915,
            ...initialOrderItemRecord
        },
    ] as TOrderItemRecord[],
    isFromStorage: false
};

export type TCreateOrder = {
    orderType?: ORDER_TYPES,
    orderProcurementId?: number,
    customerName: string
    customerPhone: string
    customerAddress: string
    customerAddressJson?: JSON
    orderDate?: string
    orderTime?: string
    orderDeliveryAmount?: number
    orderDeliveryAmountInfoCalculation?: JSON
    orderAmount?: number
    driverId?: number
    shiftSchedulingId?: number
    items: TOrderItemRecord[],
    selectedImages?: any
}