import styled from 'styled-components';
import { Flex } from '../../../../../assets/style';


export const OrderProcurementAbortContainer = styled(Flex)`
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
`;

export const OrderProcurementAbortText = styled(Flex)`
    max-width: 60%;
    padding-top: 30px;
    text-align: center;
    font: normal normal normal 24px/30px 'Readex Pro';
`;

export const OrderProcurementAbortAddressText = styled(Flex)`
    max-width: 60%;
    padding-top: 30px;
    text-align: center;
    font: normal normal normal 18px/24px 'Readex Pro';
`;