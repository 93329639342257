import React, { FC } from 'react';
import { OrderProcurementRelateImagesListContainer } from './style';
import OrderProcurementImagesListItem from './OrderProcurementImagesListItem';
import { useSelector } from 'react-redux';
import { selectOrderImages } from '../../../../../store/order/orderSlice';

type TProps = {
    id: number,
    itemId: string
    itemNumber: string
}

const OrderProcurementRelateImages: FC<TProps> = ({itemId, itemNumber}) => {

    const images = useSelector(selectOrderImages);

    return (
        <OrderProcurementRelateImagesListContainer>
            {images?.length ? images.map((image: any) => <OrderProcurementImagesListItem key={image.id} image={image} itemId={itemId} itemNumber={itemNumber} />) : null}
        </OrderProcurementRelateImagesListContainer>
    );
};

export default OrderProcurementRelateImages;