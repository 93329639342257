import styled from 'styled-components';
import { Flex } from '../../../../../assets/style';
import ButtonIcon from '../../../../../components/button/ButtonIcon';
import { Box } from '@mui/material';


export const OrderProcurementsListContainer = styled(Flex)`
   flex-direction: column;
   justify-content: flex-start;
   min-width: 500px;
   max-width: 520px;
   min-height: 170px;
   max-height: 335px;
   padding: 8px;
`;

export const OrderProcurementListHeader = styled(Flex)`
    justify-content: space-between;
    align-items: center;
    padding: 15px 10px;
    width: 100%;
`;

export const OrderProcurementListHeaderTitleContainer = styled(Flex)`
   justify-content: flex-start;
    align-content: center;
    gap: 20px;
`;


export const OrderProcurementListHeaderTitle = styled(Flex)`
    align-items: center;
    font: normal 400 22px/27.5px 'Readex Pro';
`;

export const OrderProcurementListHeaderCloseButton = styled(ButtonIcon)`
 &&& {
     color: #FFFFFF;
     font-weight: bold;
     
     svg {
         color: #FFFFFF;
     }
 }
`;

export const OrderProcurementListContent = styled(Box)`
    flex: 2;
    width: 100%;
    display: flex;
    flex-direction: column;
    max-height: 250px;
    overflow-y: scroll;
    overflow-x: hidden;
    padding-right: 10px;
`;