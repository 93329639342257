import React, { useCallback, useContext, useEffect } from 'react';
import {
    DefineOrderFreeFormContainer,
    DefineOrderRow,
    DefineOrderSectionLabel,
    OrderFreeBackButton,
    OrderFreeForm
} from '../style';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Translation from '../../../../../../components/Translation';
import { Translate } from '../../../../../../internationalization/translate';
import FormikTextField from '../../../../../../components/Formik/FormikTextField';
import CustomerPhoneField from '../components/CustomerPhoneField';
import AddressSearch from '../../../../../../components/Autocomplete/AddressSearch';
import MapPin from '../components/MapPinDrop';
import OrderFreeItemsContent from '../OrderFreeItemsContent';
import OrderFreeContext from '../context/context';
import { useFormikContext } from 'formik';
import { setOrderStarted } from '../../../../../../store/app/appSlice';
import {
    clearOrderProcurement,
    selectOrderImages,
    selectOrderProcurement,
    selectOrderSelectedImages, setOrderProcurement
} from '../../../../../../store/order/orderSlice';
import { useAppDispatch } from '../../../../../../store/hooks';
import { useSelector } from 'react-redux';
import { isString, isEmpty } from 'lodash';
import moment from 'moment-timezone';
import useGetProcurement from '../../../../../../core/api/hooks/query/procurement/useGetProcurement';



const withPreload = (Component: any) => {
    return function WrappedComponent(props: any) {
        const { setFromStorage } = useContext(OrderFreeContext);

        const initialData = (() => {
            try {
                const dd = localStorage.getItem('ORDER_STATE_ITEMS');
                return dd ? JSON.parse(dd) : [];
            } catch (e) {
                return undefined;
            }
        })();

        const initialFormik = (() => {
            try {
                const _formik = localStorage.getItem('ORDER_FORMIK');
                const dd = _formik ? JSON.parse(_formik) : {} as any;
                return (dd as any)?.orderDate ? {
                    ...dd,
                    orderDate: dd?.orderDate ? new Date(dd.orderDate as any) : new Date()
                } : undefined;
            } catch (e) {
                return undefined;
            }
        })();

        useEffect(() => {
            if (!initialFormik) return;
            const isStorage = Object.keys(initialFormik).some(x => x !== 'orderDate' && !!initialFormik[x]);
            if (!isStorage) return;
            setFromStorage(isStorage);
        }, []);


        return <Component {...props} initialData={initialData} initialFormik={initialFormik} />;
    };
};



const _OrderFreeFormDefinition = ({goBack, initialData, initialFormik}: any) => {
    const {values, setValues, resetForm} = useFormikContext();
    const orderProcurement = useSelector(selectOrderProcurement);
    const orderProcurementSelectedImages = useSelector(selectOrderSelectedImages);
    const orderProcurementImages = useSelector(selectOrderImages);
    const { saveData, setData, resetState } = useContext(OrderFreeContext);
    const dispatch = useAppDispatch();


    const {data} = useGetProcurement((values as any)?.orderProcurementId);

    useEffect(() => {
        if(!data) return;
        dispatch(setOrderProcurement(data));
    }, [data]);

    useEffect(() => {
            if(orderProcurement) {
                const orderDate = (()=> {
                   const _time = moment().tz('Europe/Belgrade').hours();
                   return moment().tz('Europe/Belgrade').subtract(+_time >= 0 && +_time <= 2 ? 1 : 0, 'day').format('YYYY-MM-DD');
                })();
                setValues({
                    orderDate,
                    customerName: orderProcurement?.account?.nickname || '',
                    customerPhone: `0${orderProcurement?.account?.phone.split(orderProcurement?.account?.phoneCode.replace(/\+/gmi, ''))?.[1]}`,
                    address: orderProcurement.address,
                    addressObject: orderProcurement.addressJson ? isString(orderProcurement.addressJson) ? JSON.parse(orderProcurement.addressJson) : orderProcurement.addressJson : null,
                    orderProcurementId: orderProcurement?.id,
                    customerBuildingNumber: orderProcurement?.buildingNumber || '',
                });
                return;
            }
            if(initialData) {
                setData(initialData);
            }
            if(initialFormik) {
                setValues(initialFormik);
            }
    }, []);

    const saveDataFn = useCallback(() => {
        const isNeedToStore = values && Object.keys(values).some(x => x !== 'orderDate' && !!(values as any)[x]);
        if (isNeedToStore) {
            localStorage.setItem('ORDER_FORMIK', JSON.stringify(values));
            !isEmpty(orderProcurementSelectedImages) && localStorage.setItem('ORDER_PROCUREMENT_SELECTED_IMAGES', JSON.stringify(orderProcurementSelectedImages));
            localStorage.setItem('ORDER_PROCUREMENT_IMAGES', JSON.stringify(orderProcurementImages));
            saveData();
            dispatch(setOrderStarted(true));
        }
    }, [values, saveData, orderProcurementSelectedImages, orderProcurementImages]);

    const handlerGoBack = useCallback(() => {
        saveDataFn();
        goBack();
    }, [goBack, saveDataFn]);

    useEffect(() => {
        return () => {
            if (!localStorage.getItem('ORDER_REMOVE')) {
                saveDataFn();
            } else {
                localStorage.removeItem('ORDER_FORMIK');
                localStorage.removeItem('ORDER_STATE_ITEMS');
                localStorage.removeItem('ORDER_PROCUREMENT_SELECTED_IMAGES');
                localStorage.removeItem('ORDER_PROCUREMENT_IMAGES');
                dispatch(setOrderStarted(false));
                dispatch(clearOrderProcurement());
                resetForm();
                resetState();
            }
        };
    }, []);

    useEffect(() => {
        return () => {
            if(!localStorage.getItem('ORDER_REMOVE')) {
                saveDataFn();
            }
        };
    }, [saveDataFn]);
    return (
        <OrderFreeForm>
            <DefineOrderFreeFormContainer>
                <OrderFreeBackButton title={'back'} onClick={handlerGoBack}><ArrowBackIcon /></OrderFreeBackButton>
                <Translation use={Translate.defineOrderSectionCustomerTitle} as={DefineOrderSectionLabel} />
                <DefineOrderRow margin justify={'space-between'}>
                    <FormikTextField
                        name={'customerName'}
                        variant={'outlined'}
                        label={Translate.defineOrderCustomerNameLabel}
                    />
                    <CustomerPhoneField />
                    <AddressSearch isFormik name={'address'} label={Translate.destination} showSwitch />
                    <MapPin realField={'address'} />
                    <FormikTextField
                        name={'customerBuildingNumber'}
                        variant={'outlined'}
                        label={Translate.defineOrderCustomerBuildingNumberLabel}
                        className={'customer-building-number'}
                    />
                </DefineOrderRow>
                <OrderFreeItemsContent />
            </DefineOrderFreeFormContainer>
        </OrderFreeForm>
    );
};

const OrderFreeFormDefinition = withPreload(_OrderFreeFormDefinition);

export default OrderFreeFormDefinition;