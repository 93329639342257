import React, { FC, useCallback, useMemo } from 'react';
import {
    OrderProcurementImagesListItemContainer,
    OrderProcurementImagesListItemImage,
    OrderProcurementImagesListItemSelectedByContainer,
    OrderProcurementImagesListItemSelectedByNumber,
    OrderProcurementRelateImagesListItemCheckbox
} from './style';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import { selectOrderProcurementImage, selectOrderSelectedImages } from '../../../../../../store/order/orderSlice';
import { useAppDispatch } from '../../../../../../store/hooks';
import { useSelector } from 'react-redux';

type TProps = {
    image: any
    itemId: string
    itemNumber: string
}

const OrderProcurementImagesListItem: FC<TProps> = ({ image, itemId, itemNumber }) => {
    const selectedImages = useSelector(selectOrderSelectedImages);
    const dispatch = useAppDispatch();

    const [isSelected, selectedBy, isAllowSelected] = useMemo(() => ([
        !!selectedImages?.[itemId]?.find((x: any) => x.id === image.id),
        image?.selectedByNumber,
        (image?.selectedBy && `${image?.selectedBy}` === `${itemId}`) || !image?.selectedBy,
    ]), [selectedImages, image, itemId]);


    const onClick = useCallback((e: any) => {
        if(!isAllowSelected) return;
        e.stopPropagation();
        dispatch(selectOrderProcurementImage({
            image,
            itemId,
            itemNumber
        }));
    }, [itemNumber, itemId, image, dispatch, isAllowSelected]);



    return (
        <OrderProcurementImagesListItemContainer onClick={onClick} isSelected={isSelected || !!selectedBy} selectAllow={isAllowSelected}>
            {isAllowSelected ? <OrderProcurementRelateImagesListItemCheckbox>
                {isSelected ? <CheckOutlinedIcon /> : null}
            </OrderProcurementRelateImagesListItemCheckbox> : null}
            <OrderProcurementImagesListItemImage src={image.image} alt={'image'} />
            {selectedBy && <OrderProcurementImagesListItemSelectedByContainer>
                <OrderProcurementImagesListItemSelectedByNumber>{selectedBy}</OrderProcurementImagesListItemSelectedByNumber>
            </OrderProcurementImagesListItemSelectedByContainer>}
        </OrderProcurementImagesListItemContainer>
    );
};

export default OrderProcurementImagesListItem;