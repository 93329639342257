export enum VEHICLE_TYPE {
    CAR = 0x1,
    MOTORCYCLE = 0x2,
    BIKE = 0x4,
}

export enum ORDER_STATUS {
    PENDING = 0x1,
    PENDING_SA= 0x2,
    ACCEPTED = 0x3,
    REJECTED = 0x4,
    ASSIGNED = 0x5,
    READY = 0x6,
    PICK_UP = 0x7,
    PENDING_CAN_T_COMPLETE = 0x8,
    PENDING_EDIT_PRICE = 0x9,
    COMPLETED = 0xA,
    ARCHIVED = 0xB,
    FREE_STOP_ADDED = 0xC,
    SCHEDULED = 0xD
}

export enum ORDER_BIDDING_STATUS {
    DELETED = 0,
    PENDING = 1,
    START = 2,
    TERMINATE = 3,
    FINISHED = 4
}

export enum ORDER_BID_STATUS {
    NOT_BID = 0,
    BID = 1,
    WON = 2
}

export enum ORDER_AMOUNT_DELIVERY_TYPES {
    START_PRICE = 1,
    START_PRICE_KM = 2,
    FUTURE_POINT_PURCHASE = 3,
    STOP_PRICE= 4,
    STOP_PRICE_NO_ADDRESS = 5,
    STOP_PRICE_PURCHASE_NO_ADDRESS,
    STOP_PRICE_PURCHASE
}

export enum ORDER_TYPES {
    ORDER_FREE = 0x1,
    ORDER_PROCUREMENT = 0x2,
    ORDER_USER = 0x3,
}