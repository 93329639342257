import { configureStore } from '@reduxjs/toolkit';
import app from './app/appSlice';
// ...
import order from './order/orderSlice';

const store = configureStore({
    reducer: {
        app,
        order
    }
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

export default store;