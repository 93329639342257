import React, { useCallback, useMemo, useState } from 'react';
import {
    PreviewItemDescription,
    PreviewItemRow,
    PreviewOrderEditButtonItemDescription,
    PreviewOrderItemAddress,
    PreviewOrderItemBox,
    PreviewOrderItemCompletedBox,
    PreviewOrderPurchaseIcon
} from './style';
import moment from 'moment-timezone';
import { formatCurrency } from '../../../../../../utils';
import Collapse from '../../../../../../components/Collapse';
import { Translate } from '../../../../../../internationalization/translate';
import Translation from '../../../../../../components/Translation';
import {
    PreviewOrderColumn,
    PreviewOrderFinishImage,
    PreviewOrderFinishNoteText,
    PreviewOrderFinishText,
    PreviewOrderFinishTimeText,
    PreviewOrderItemPickUpTimeText
} from '../style';
import EditIcon from '@mui/icons-material/Edit';
import PriceChangeIcon from '@mui/icons-material/PriceChange';
import AvTimerIcon from '@mui/icons-material/AvTimer';
import PreviewOrderItemEditDescription from './EditDescription/PreviewOrderItemEditDescription';
import { ORDER_STATUS } from '../../../../d';
import PreviewOrderItemEditPrice from './EditPrice/PreviewOrderItemEditPrice';
import PreviewOrderItemEditPickUpValue from './EditPickUpValue/PreviewOrderItemEditPickUpValue';
import PreviewProcurementItemImages from '../PreviewProcurementItemImages';
import { useTranslation } from 'react-i18next';
import ClearIcon from '@mui/icons-material/Clear';
import { useModal } from '../../../../../../hooks/useModal';
import PreviewOrderItemRemoveItem from '../Dialogs/PreviewOrderItemRemoveItem';


const PreviewOrderItem = ({ item, index, status, orderId, order }: any) => {
    const {t} = useTranslation();
    const [edit, setEdit] = useState<boolean>(false);
    const [editPrice, setEditPrice] = useState<boolean>(false);
    const [editPickUpValue, setEditPickUpValue] = useState<boolean>(false);

    const [time, amount, completedTime, isOrange, isRed, completedText, isCompleted, isEditable, isPickUpValue, isRemoveble, procurementImages] = useMemo(() => {
        const diff = item?.pickUpTime ? moment.duration(moment(item?.pickUpTime).tz('Europe/Belgrade').diff(moment().tz('Europe/Belgrade')))?.asMinutes() : '';
        const diffCompleted = item?.completedTime ? moment.duration(moment(item?.pickUpTime).tz('Europe/Belgrade').diff(moment(item?.completedTime).tz('Europe/Belgrade')))?.asMinutes() : 0;
        return [
            item?.completedTime ? moment(item?.completedTime).tz('Europe/Belgrade').format('YYYY-MM-DD HH:mm') : item?.pickUpTime ? moment(item?.pickUpTime).tz('Europe/Belgrade').format('YYYY-MM-DD HH:mm') : '',
            item?.amount ? formatCurrency(item?.amount, false) : '',
            item?.completedTime ? moment(item?.completedTime).tz('Europe/Belgrade').format('YYYY-MM-DD HH:mm') : '',
            Number(item?.pickUpTime && (diff >= 15 || (diff <= 15 && diff >= 11))),
            Number(!item?.isCompleted && item?.pickUpTime && diff <= 10),
            item?.completedTime && diffCompleted && diffCompleted > 0 ? Math.floor(diffCompleted) : '',
            item?.isCompleted,
            !item?.isCompleted && ![ORDER_STATUS.COMPLETED, ORDER_STATUS.REJECTED, ORDER_STATUS.ARCHIVED].includes(status),
            !!item?.pickUpValue,
            order?.items?.length >= 2 && ![ORDER_STATUS.COMPLETED, ORDER_STATUS.REJECTED].includes(order.status) && !order.isAborted && !order.isCustomerNotShow,
            item?.procurementData
        ];
    }, [item, status, order]);

    const { openModal: openDialogRemoveOrderItem, closeModal: closeDialogRemoveOrderItem } = useModal({
        modal: PreviewOrderItemRemoveItem,
        isActionsButtonsVisible: true,
        isCloseAction: false,
        noPadding: false
    });

    const handlerRemoveOrderItem = useCallback(() => {
        if(!item || !item?.id) return;
        openDialogRemoveOrderItem({
            closeDialog: closeDialogRemoveOrderItem,
            item,
            number: index
        });
    }, [openDialogRemoveOrderItem, closeDialogRemoveOrderItem, item, index]);

    const handleEdit = useCallback(() => {
        setEdit(v => !v);
    }, [setEdit]);

    const handleEditPrice = useCallback(()=> {
        setEditPrice(v => !v);
    },[setEditPrice]);


    const handleEditPickUpValue = useCallback(()=> {
        setEditPickUpValue(v => !v);
    },[setEditPickUpValue]);

    const handleCancelEditPickUpValue = useCallback(() => {
        setEditPickUpValue(false);
    }, [setEditPickUpValue]);

    const handleCancelEdit = useCallback(() => {
        setEdit(false);
    }, [setEdit]);

    const handleCancelEditPrice = useCallback(() => {
        setEditPrice(false);
    }, [setEditPrice]);



    return (
        <PreviewOrderItemBox isCompleted={item?.isCompleted}>
            <PreviewItemRow>
                <PreviewItemRow gap={'15'}>
                    <PreviewOrderItemCompletedBox
                        isCompleted={item.isCompleted}
                    />
                    <div>#{index}</div>
                    {item?.isPurchase ? <span title={t(Translate.orderPreviewIsPurchaseTitle)}><PreviewOrderPurchaseIcon /></span> : null}
                </PreviewItemRow>
                <PreviewItemRow gap={10}>
                    {time ? <Translation
                        use={Translate.orderPreviewItemsPickUpTime}
                        as={PreviewOrderItemPickUpTimeText}
                        params={{ time, completed: isCompleted ? 'o' : 'i' }}
                        isOrange={isOrange}
                        isRed={isRed}
                    /> : null}
                    {isEditable && !edit ? <PreviewOrderEditButtonItemDescription title={Translate.orderPreviewItemsPickUpTimeEditTitle} onClick={handleEdit}>
                            <EditIcon />
                        </PreviewOrderEditButtonItemDescription>
                        : null}
                    {isCompleted && !editPrice ? <PreviewOrderEditButtonItemDescription title={Translate.orderPreviewItemsPriceEditTitle}  onClick={handleEditPrice}>
                            <PriceChangeIcon />
                        </PreviewOrderEditButtonItemDescription>
                        : null}
                    {isPickUpValue && !isCompleted && !editPickUpValue ? <PreviewOrderEditButtonItemDescription title={Translate.orderPreviewItemsDescriptionEditTitle}  onClick={handleEditPickUpValue}>
                            <AvTimerIcon />
                        </PreviewOrderEditButtonItemDescription>
                        : null}
                    {isRemoveble ?  <PreviewOrderEditButtonItemDescription title={Translate.orderPreviewItemsRemoveTitle}  onClick={handlerRemoveOrderItem}>
                            <ClearIcon />
                    </PreviewOrderEditButtonItemDescription>: null}
                </PreviewItemRow>
            </PreviewItemRow>
            <PreviewItemRow>
                <PreviewOrderItemAddress isCompleted={item?.isCompleted}>{item.address}</PreviewOrderItemAddress>
                {amount ? isCompleted && editPrice ? <PreviewOrderItemEditPrice item={item} cancelEdit={handleCancelEditPrice} orderId={orderId} /> : <Translation use={Translate.orderPreviewItemsAmount} as={'div'} params={{ amount }} /> : null}
            </PreviewItemRow>
            <PreviewItemDescription>
                {isEditable && edit ? <PreviewOrderItemEditDescription item={item} cancelEdit={handleCancelEdit} orderId={orderId} />: item?.description }
            </PreviewItemDescription>
            {procurementImages?.length ? <PreviewProcurementItemImages images={procurementImages}/> : null}
            {isPickUpValue && !isCompleted && editPickUpValue ? <PreviewOrderItemEditPickUpValue item={item} cancelEdit={handleCancelEditPickUpValue} orderId={orderId} /> : null}
            {item?.isCompleted ? <Collapse label={Translate.orderPreviewItemsFinishInformation}>
                <PreviewItemRow gap={'20'} alignItems={'flex-start'}>
                    <PreviewOrderFinishImage
                        width={100}
                        height={100}
                        alt={'Finish Image'}
                        src={item.image}
                    />
                    <PreviewOrderColumn flex={2}>
                        <PreviewItemRow>
                            {completedText ? <Translation
                                use={Translate.orderPreviewItemsCompletedText}
                                as={PreviewOrderFinishText}
                                isOrange={isOrange}
                                isRed={isRed}
                                params={{ time: completedText }}
                            /> : <>&nbsp;</>}
                            <Translation
                                use={Translate.orderPreviewItemsCompletedTime}
                                as={PreviewOrderFinishTimeText}
                                params={{ time: completedTime }}
                            />
                        </PreviewItemRow>
                        <PreviewOrderFinishNoteText>
                            {item?.note}
                        </PreviewOrderFinishNoteText>
                    </PreviewOrderColumn>
                </PreviewItemRow>
            </Collapse> : null}
        </PreviewOrderItemBox>
    );
};

export default PreviewOrderItem;