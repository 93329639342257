import {
    DefineOrderForm,
    DefineOrderIsScheduled,
    DefineOrderRow,
    DefineOrderSectionLabel,
    DefineOrderTime
} from '../style';
import Translation from '../../../../../../components/Translation';
import { Translate } from '../../../../../../internationalization/translate';
import { OrdersHeaderAddButton } from '../../../../style';
import AddIcon from '@mui/icons-material/Add';
import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import OrderFreeContext from '../context/context';
import {
    DefineOrderCalculateButton,
    DefineOrderFreeButton,
    DefineOrderFreeItemsContent,
    DefineOrderFreeItemsContentContainer,
    DefineOrderFreeItemsFooter,
    DefineOrderRemoveButton
} from './style';
import EmptyOrderFreeContent from './EmptyOrderFreeContent';
import OrderFreeItem from './OrderFreeItem';
import { useFormikContext } from 'formik';
import useGetCurrentDriversQuery from '../../../../../../core/api/hooks/query/orders/useGetCurrentDriversQuery';
import FormikSelectField from '../../../../../../components/Formik/FormikSelectField';
import FormikInputCurrency from '../../../../../../components/Formik/FormikInputCurrency';
import FormikDatepicker from '../../../../../../components/Formik/FormikDatepicker';
import FormikTimepicker from '../../../../../../components/Formik/FormikTimepicker';
import { Delete } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { initialValuesOrderFree } from '../validation';
import { setOrderStarted } from '../../../../../../store/app/appSlice';
import { useAppDispatch } from '../../../../../../store/hooks';
import FunctionsIcon from '@mui/icons-material/Functions';
import useOrderCalculationMutation from '../../../../../../core/api/hooks/mutations/order/useOrderCalculationMutation';
import UiDataContext from '../../../../../../context/UiContext/context';
import { errorToast } from '../../../../../../components/Utils/Toast';
import { pick } from 'lodash';
import { formatNumber } from '../../../../../../utils';
import OrderDeliveryAmountPopoverInfo from './OrderDeliveryAmountPopoverInfo';
import { clearOrderProcurement } from '../../../../../../store/order/orderSlice';


const OrderDeliverPopoverPriceInfo = () => {
    const { values } = useFormikContext() as any;
    return <OrderDeliveryAmountPopoverInfo data={values} />;
};


const OrderFreeItemsContent = () => {
    const { t } = useTranslation();
    const { values, setValues, setFieldValue, resetForm, isSubmitting, setFieldTouched } = useFormikContext() as any;
    const { setLoading, resetLoading } = useContext(UiDataContext);
    const { items, addItem, isFromStorage, resetState } = useContext(OrderFreeContext);
    const dispatch = useAppDispatch();

    const handlerAddItem = useCallback(() => {
        const id = new Date().getTime() + 1;
        addItem(id);
    }, [addItem]);


    const isShowOrderCalculate = useMemo(() => !!(items.filter((x: any) => !!values?.[`${x.id}_address`])?.length && values?.address), [items, values]);

    const orderCalculationMutation = useOrderCalculationMutation();

    const handleOrderCalculate = useCallback(async () => {
        try {
            setLoading();
            const _items = items.map((x: any) => {
                const pickUpValue = (() => {
                    const pickUpValue = values?.[`${x.id}_pickUpValueNum`] ? values?.[`${x.id}_pickUpValueNum`] :
                        values?.[`${x.id}_pickUpValueMask`] ? values?.[`${x.id}_pickUpValueMask`] : undefined;
                    if (/:/gmi.test(pickUpValue)) {
                        const arr = pickUpValue.split(':');
                        return `${Number(arr[0])}:${arr[1]}`;
                    }
                    return Number(pickUpValue);
                })();
                return {
                    tempId: x.id,
                    description: values[`${x.id}_description`],
                    address: values[`${x.id}_address`],
                    addressJson: values?.[`${x.id}_addressObject`],
                    isPurchase: Number(values?.[`${x.id}_isPurchase`]),
                    pickUpValue,
                    amount: !isNaN(Number(values?.[`${x.id}_amount`]?.replace(/\./gmi, '').replace(/,/gi, '.'))) ? Number(values?.[`${x.id}_amount`]?.replace(/\./gmi, '').replace(/,/gi, '.')) : void (0)
                };
            });
            const result = await orderCalculationMutation.mutateAsync({
                ...(pick(values, ['customerName', 'customerPhone', 'customerBuildingNumber', 'customerAddress', 'customerAddressJson']) || {}),
                customerAddress: values.address,
                customerAddressJson: values.addressObject,
                items: _items
            });
            if (result?.status === 200) {
                await setFieldValue('orderDeliveryAmount', formatNumber(result.data?.value), true);
                await setFieldValue('orderDeliveryAmountInfoCalculation', {
                    items: result?.data?.infoText,
                    km: result?.data?.km,
                    furthestPoint: result?.data?.furthestPoint
                }, false);
            }
        } catch (e: any) {
            errorToast(t(e?.response?.data?.key || e?.response?.data?.message));
        } finally {
            resetLoading();
        }
    }, [orderCalculationMutation, values, setLoading, resetLoading, items, setFieldTouched, setFieldValue]);


    const { data: listState } = useGetCurrentDriversQuery({ active: true });


    const options = useMemo(() => (listState || []).map((x: any) => ({
        label: `${x?.driver?.firstName} ${x?.driver?.lastName}`,
        value: `${x.driverId}`
    })), [listState]);


    useEffect(() => {
        if (!items) return;
        let obj = {};
        items.forEach((x: any, index: number) => {
            obj = {
                ...obj,
                [`${x.id}_num`]: index + 1,
                [`${x.id}_locationName`]: values?.[`${x.id}_locationName`] || x?.[`${x.id}_locationName`] || x?.['locationName'],
                [`${x.id}_description`]: values?.[`${x.id}_description`] || x?.[`${x.id}_description`] || x?.['description'],
                [`${x.id}_address`]: values?.[`${x.id}_address`] || x?.[`${x.id}_address`] || x?.['address'],
                [`${x.id}_addressObject`]: values?.[`${x.id}_addressObject`] || x?.[`${x.id}_addressObject`] || x?.['_addressObject'],
                [`${x.id}_pickUpValue`]: values?.[`${x.id}_pickUpValue`] || x?.[`${x.id}_pickUpValue`] || x?.['_pickUpValue'],
                [`${x.id}_amount`]: values?.[`${x.id}_amount`] || x?.[`${x.id}_amount`] || x?.['amount'],
                [`${x.id}_isPurchase`]: !!(values?.[`${x.id}_isPurchase`] || x?.[`${x.id}_isPurchase`] || x?.['isPurchase'])
            };
        });
        setValues({
            ...(values || {}),
            ...obj
        });
    }, [items]);

    const changeIsScheduled = useCallback((value: boolean) => {
        if (!value) {
            setFieldValue('orderTime', '', false);
            setFieldValue('driverId', values.tempDriverId, false);
            setFieldValue('tempDriverId', undefined as any, false);
        } else {
            setFieldValue('tempDriverId', values.driverId, false);
            setFieldValue('driverId', undefined as any, false);
        }
    }, [setFieldValue, values]);

    const clearStorage = useCallback(() => {
        localStorage.setItem('ORDER_REMOVE', '1');
        resetState();
        resetForm({
            values: { ...initialValuesOrderFree },
            errors: {},
            touched: {}
        });
        dispatch(clearOrderProcurement());
        localStorage.removeItem('ORDER_FORMIK');
        localStorage.removeItem('ORDER_STATE_ITEMS');
        localStorage.removeItem('ORDER_PROCUREMENT_SELECTED_IMAGES');
        localStorage.removeItem('ORDER_PROCUREMENT_IMAGES');
        localStorage.setItem('ORDER_REMOVE', '1');
        dispatch(setOrderStarted(false));
    }, [resetState, resetForm, dispatch]);


    return (
        <DefineOrderForm>
            <DefineOrderRow justify={'space-between'}>
                <Translation use={Translate.defineOrderSectionItemsTitle} as={DefineOrderSectionLabel} />
                <OrdersHeaderAddButton
                    onClick={handlerAddItem}
                    startIcon={<AddIcon />}
                >
                    <Translation use={Translate.add} />
                </OrdersHeaderAddButton>
            </DefineOrderRow>
            <DefineOrderFreeItemsContentContainer>
                {items.length ? (<DefineOrderFreeItemsContent>
                    {items.map((item: any, index: number) => <OrderFreeItem item={item} itemNumber={index + 1}
                                                                            key={index} />)}
                </DefineOrderFreeItemsContent>) : <EmptyOrderFreeContent />}
            </DefineOrderFreeItemsContentContainer>
            <DefineOrderRow>
                <FormikSelectField
                    name={'driverId'}
                    variant={'outlined'}
                    label={Translate.defineOrderFooterDriver}
                    options={options}
                    clearable
                    disabled={!!values?.isScheduled}
                />
                <FormikInputCurrency
                    name={'orderDeliveryAmount'}
                    variant={'outlined'}
                    label={Translate.defineOrderFooterDeliveryAmount}
                    iconRight={{
                        icon: OrderDeliverPopoverPriceInfo
                    }}
                />

                <FormikDatepicker
                    name={'orderDate'}
                    variant={'outlined'}
                    label={Translate.defineOrderFooterOrderDate}
                    disabled={!values?.isScheduled}
                />
                <DefineOrderTime>
                    <DefineOrderIsScheduled
                        name={'isScheduled'}
                        label={Translate.defineOrderFooterIsScheduled}
                        type={'checkbox'}
                        labelPlacement={'start'}
                        handlerAfterChange={changeIsScheduled}
                    />
                    <FormikTimepicker
                        name={'orderTime'}
                        variant={'outlined'}
                        label={Translate.defineOrderFooterOrderTime}
                        disabled={!values?.isScheduled}
                    />
                </DefineOrderTime>
            </DefineOrderRow>
            <DefineOrderFreeItemsFooter>
                <DefineOrderFreeButton type="submit" disabled={isSubmitting}>
                    <Translation use={Translate.create} />
                </DefineOrderFreeButton>
                {isShowOrderCalculate && <DefineOrderCalculateButton title={t(Translate.calculateOrderData)}
                                                                     onClick={handleOrderCalculate}><FunctionsIcon /></DefineOrderCalculateButton>}
                {isFromStorage && <DefineOrderRemoveButton title={t(Translate.clearOrderData)}
                                                           onClick={clearStorage}><Delete /></DefineOrderRemoveButton>}
            </DefineOrderFreeItemsFooter>
        </DefineOrderForm>
    );
};


export default OrderFreeItemsContent;