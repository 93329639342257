import styled from 'styled-components';
import { Flex } from '../../../../../assets/style';
import { Colors } from '../../../../../theme/palette';
import Button from '../../../../../components/button';

export const OrderProcurementRelateImagesContainer = styled(Flex)`
    padding: 20px;
    flex-direction: column;
    gap: 15px;
    min-width: 700px;
    min-height: 700px;
`;


export const OrderProcurementRelateImagesListContainer = styled(Flex)`
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex: 2;
    gap: 15px;
`;

export const OrderProcurementRelateImagesListHeader = styled(Flex)`
    min-height: 50px;
    justify-content: space-between;
    align-items: center;
`;

export const OrderProcurementRelateImagesListHeaderItemNumber = styled(Flex)`
    font-size: 20px;
    line-height: 28px;
    font-weight: bolder;
    color: ${Colors.white};
`;

export const OrderProcurementRelateImagesButtonSelectAll = styled(Button)<{isChanged?: number}>`
    &&& {
        max-width: 170px;
        font-size: 14px;
        line-height: 18px;
        padding: 5px 10px;
    }
`;