import styled, { css } from 'styled-components';
import { Flex } from 'assets/style';
import RenderLabelValue from '../../../../../components/Utils/RenderLabelValue';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Colors } from '../../../../../theme/palette';
import { LabelPart } from '../../../../../components/Utils/RenderLabelValue/style';


export const PreviewOrderContainer = styled(Flex)`
    min-width: 850px;
    flex-direction: column;
    padding: 20px;   
    gap: 15px;
    min-height: 700px;
    max-height: 700px;
`;

export const PreviewOrderHeader = styled(Flex)`
    width: 100%;
    flex-direction: column;
    position: relative;
`;

export const PreviewOrderRow = styled(Flex)<{alignItems?: string, justify?: string, gap?: string}>`
    gap: ${props => props.gap || '10px'};
    align-items: ${props => props?.alignItems || 'flex-start'};
    justify-content: ${props => props?.justify || 'space-between'};
    z-index: 10;
`;

export const PreviewOrderColumn = styled(Flex)<{flex?: number}>`
    flex: ${props => props.flex !== undefined ? props.flex : 1};
    flex-direction: column;
    gap: 10px;
`;

export const PreviewOrderItemPickUpTimeText =styled(Flex)<{isOrange?: number, isRed?: number}>`
    font: normal 400 16px/125% "Readex Pro";
    padding-right: 10px;
    ${props => !!props.isOrange && css`
     color: ${Colors.orange};
   `}
    ${props => !!props.isRed && css`
     color: ${Colors.red};
   `}
    text-shadow: 0.5px 0.5px 0.5px #494949;
`;


export const PreviewOrderCustomerContainer = styled(RenderLabelValue).attrs({
    isRow: true
})<{flex?: number}>`
    flex: ${props => props.flex || 1};
    padding-right: 0;
    
    ${LabelPart} {
        min-width: 160px;
    }
`;

export const PreviewOrderCustomerColumnContainer = styled(RenderLabelValue).attrs({
    isRow: false
})<{flex?: number}>`
    flex: ${props => props.flex || 1};
`;

export const PreviewOrderFinishImage =styled(LazyLoadImage)`
    &&& {
        max-width: 100%;
        max-height: 100%;
    }
`;


export const PreviewOrderFinishTimeText = styled(Flex)`
    justify-content: flex-end;
    font: normal 400 13px/100% "Readex Pro";
`;

export const PreviewOrderFinishText = styled(Flex)<{isOrange?: number, isRed?: number}>`
    justify-content: flex-end;
    font: normal 400 13px/100% "Readex Pro";
    ${props => !!props.isOrange && css`
     color: ${Colors.orange};
   `}
    ${props => !!props.isRed && css`
     color: ${Colors.red};
   `}
`;

export const PreviewOrderFinishNoteText = styled(Flex)`
    justify-content: flex-start;
`;

export const PreviewOrderItemsContainer = styled(Flex)`
    flex-direction: column;
    flex: 2;
    gap: 20px;
    max-height: 550px;
    overflow-y: auto;
`;

export const PreviewOrderIsAbortedText = styled(Flex)`
    position: absolute;
    bottom: -10px;
    right: 0;
    font-size: 3rem;
    opacity: 0.20;
    white-space: nowrap;
    z-index: 1;
    pointer-events: none;
    color: ${Colors.red};    
    svg {
        width: 120px;
        height: 120px;
    }    
`;

export const PreviewOrderIsScheduledText = styled(Flex)`
    position: absolute;
    bottom: -10px;
    right: 0;
    font-size: 3rem;
    opacity: 0.60;
    white-space: nowrap;
    z-index: 0;
    pointer-events: none;
    color: ${Colors.grey70};
    svg {
        width: 120px;
        height: 120px;
    }    
`;

export const PreviewOrderScheduledText = styled(Flex)`
    position: absolute;
    top: 10px;
    left: 10px;
    font-size: 1.2rem;
    white-space: nowrap;
    z-index: 0;
    pointer-events: none;
    color: ${Colors.grey60};
`;

export const PreviewOrderBidContainer = styled(RenderLabelValue).attrs({
    isRow: true
})<{flex?: number}>`     
`;

export const PreviewOrderDeliveryAmountRow = styled(Flex)`
    gap: 10px;
    align-items: center;
`;

export const PreviewOrderDeliveryAmountJson = styled(Flex)`
`;