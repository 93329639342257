import React, { FC, useCallback } from 'react';
import {
    OrderProcurementRelateImagesButtonSelectAll,
    OrderProcurementRelateImagesContainer,
    OrderProcurementRelateImagesListHeader,
    OrderProcurementRelateImagesListHeaderItemNumber
} from './style';
import useModalFormData from '../../../../../hooks/useModalFormData';
import { Translate } from '../../../../../internationalization/translate';
import OrderProcurementImagesList from './OrderProcurementImagesList';
import Translation from '../../../../../components/Translation';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import { selectAllImages, selectIsAllSelected, unSelectAll } from '../../../../../store/order/orderSlice';

type TProps = {
    id: number
    itemId: string
    itemNumber: string
}

const OrderProcurementRelateImages: FC<TProps> = ({ id, itemNumber, itemId }) => {
    const isAllSelected = useAppSelector(selectIsAllSelected);
    const dispatch = useAppDispatch();

    useModalFormData({
        modalTitle: Translate.orderProcurementRelatedItemsModalTitle
    });

    const handleSelectAll = useCallback(()=> {
        if(!itemId) return;
        dispatch(selectAllImages({
            itemId,
            itemNumber
        }));
    },[itemId, itemNumber, dispatch]);
    
    const handleUnSelectAll= useCallback(()=> {
        if(!isAllSelected) return;
        dispatch(unSelectAll({
            itemId
        }));
    },[isAllSelected, dispatch]);

    return (
        <OrderProcurementRelateImagesContainer>
            <OrderProcurementRelateImagesListHeader>
                <OrderProcurementRelateImagesListHeaderItemNumber>Stop #{itemNumber}</OrderProcurementRelateImagesListHeaderItemNumber>
                <OrderProcurementRelateImagesButtonSelectAll onClick={!isAllSelected ? handleSelectAll : handleUnSelectAll}>
                    <Translation use={!isAllSelected ? Translate.selectAll : Translate.undo}  />
                </OrderProcurementRelateImagesButtonSelectAll>
            </OrderProcurementRelateImagesListHeader>
            <OrderProcurementImagesList id={id} itemId={itemId} itemNumber={itemNumber} />
        </OrderProcurementRelateImagesContainer>
    );
};

export default OrderProcurementRelateImages;