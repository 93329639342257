import React, { FC, useCallback, useContext } from 'react';
import { OrderProcurementAbortAddressText, OrderProcurementAbortContainer, OrderProcurementAbortText } from './style';
import Translation from '../../../../../components/Translation';
import { Translate } from '../../../../../internationalization/translate';
import useModalFormData from '../../../../../hooks/useModalFormData';
import UiDataContext from '../../../../../context/UiContext/context';
import { useTranslation } from 'react-i18next';
import useProcurementAbortMutation
    from '../../../../../core/api/hooks/mutations/procurement/useProcurementAbortMutation';
import { errorToast, successToast } from '../../../../../components/Utils/Toast';
import { queryClient } from '../../../../../core/root/ReactQueryProvider';

type TProps = {
    procurement?: any
    closeDialog?: () => void
}

const OrderProcurementAbort: FC<TProps> = ({procurement, closeDialog}) => {

    const { setLoading, resetLoading } = useContext(UiDataContext);
    const {t} = useTranslation();

    const procurementAbortMutation = useProcurementAbortMutation();


    const handlerOnSubmit = useCallback(async () => {
        if (!procurement?.id) return false;
        try {
            setLoading();
            const result = await procurementAbortMutation.mutateAsync({
                id: procurement.id
            });
            if (result?.status === 200) {
                successToast(t(result.message));
                await queryClient.invalidateQueries({queryKey: ['get-procurements']});
                await queryClient.invalidateQueries({queryKey: ['get-procurements-count']});
                closeDialog && closeDialog();
            }
        }catch (e: any) {
            errorToast(t(e?.response?.data?.key || e?.response?.data?.message));
            return false;
        }finally {
            resetLoading();
        }
        return true;
    },[procurement, setLoading, resetLoading, closeDialog, procurementAbortMutation]);


    useModalFormData({
        modalTitle: Translate.orderProcurementAbortTitle,
        handlerOnSubmit
    });

    return (
        <OrderProcurementAbortContainer>
            <Translation use={Translate.orderProcurementAbortText} as={OrderProcurementAbortText} params={{
                procurementNumber: procurement?.procurementNumber
            }}/>
            <Translation use={Translate.orderProcurementAbortAddressText} as={OrderProcurementAbortAddressText} params={{
                address: procurement?.address
            }}/>
        </OrderProcurementAbortContainer>
    );
};

export default OrderProcurementAbort;