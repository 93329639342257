import styled from 'styled-components';
import { Flex } from '../../../../../assets/style';
import ButtonIcon from '../../../../../components/button/ButtonIcon';


export const OrderProcurementListItemContainer = styled(Flex)`
    padding: 10px 17px 10px 5px;
    justify-content: space-between;
    align-items: center;
    gap: 15px;
    border-bottom: 1px solid #FFFFFF;
    min-height: 75px;

    &:first-child {
        border-top: 1px solid #FFFFFF;
    }
`;

export const OrderProcurementListItemContent = styled(Flex)`
        flex: 2;
        flex-direction: column;
        justify-content: flex-start;
`;

export const OrderProcurementListItemContentRow = styled(Flex)`
        flex: 2;
        justify-content: flex-start;
        gap: 10px;
`;

export const OrderProcurementListItemContentTitle = styled(Flex)`
    color: #FFFFFF;
    font: normal 400 16px/24px 'Readex Pro';
`;

export const OrderProcurementListItemContentSubText = styled(Flex)`
    font: normal 400 12px/15px 'Readex Pro';
    color: #FFFFFF;
    letter-spacing: 1px;
`;

export const OrderProcurementListItemRightPart = styled(Flex)`
    flex-direction: column;
    height: 100%;
    min-height: 54px;
    justify-content: flex-end;
`;

export const OrderProcurementListItemActions = styled(Flex)`
    justify-content: space-around;
    align-items: center;
    gap: 20px;
    flex: 2;
`;

export const OrderProcurementListActionButton = styled(ButtonIcon)<{color?: string}>`
    &&& {
        width: 26px;
        height: 26px;
        color: ${props => props?.color || '#11D600'};
    }
`;

