import styled from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';


export const PreviewProcurementItemImagesSwiper = styled(Swiper)`
    &&& {
        width: 100%;
        padding-top: 10px;
        padding-bottom: 10px;
        
        .swiper-wrapper {
            gap: 15px;            
        }
    }
`;

export const PreviewProcurementItemImagesSwiperSlide = styled(SwiperSlide)`
    &&& {
        background-position: center;
        background-size: cover;
        width: 150px;
        height: 150px;
    }
`;

export const PreviewProcurementItemImagesSwiperSlideImage = styled.img`
    &&& {
        display: block;
        width: 100%;
    }
`;

