export const Translate = {
    /** BulkGate errors */

    invalid_phone_number: 'invalid_phone_number',
    banned: 'banned',
    empty_message: 'empty_message',
    blacklisted_number: 'blacklisted_number',
    low_credit: 'low_credit',
    unsupported_api_version: 'unsupported_api_version',

    /** BE */
    forbiddenError: 'forbiddenError',
    invalidAction: 'invalidAction',
    driverCreated: 'driverCreated',
    driverNotExist: 'driverNotExist',
    driverRemoved: 'driverRemoved',
    driverUpdatedSuccess: 'driverUpdatedSuccess',
    driverAccountNotExist: 'driverAccountNotExist',

    driverSuccessActivated: 'driverSuccessActivated',
    driverSuccessBlocked: 'driverSuccessBlocked',
    driverSuccessUnblocked: 'driverSuccessUnblocked',
    driverReactivatedSuccess: 'driverReactivatedSuccess',
    restaurantExist: 'restaurantExist',
    businessTypeNotExist: 'businessTypeNotExist',
    restaurantCreated: 'restaurantCreated',
    restaurantRemovedSuccess: 'restaurantRemovedSuccess',
    restaurantNotExist: 'restaurantNotExist',
    restaurantAccountNotExist: 'restaurantAccountNotExist',
    restaurantBlockedSuccess: 'restaurantBlockedSuccess',
    restaurantUnblockedSuccess: 'restaurantUnblockedSuccess',
    restaurantReactivatedSuccess: 'restaurantReactivatedSuccess',
    restaurantUpdatedSuccess: 'restaurantUpdatedSuccess',
    userRemovedSuccess: 'userRemovedSuccess',
    userNotExist: 'userNotExist',
    userBlockedSuccess: 'userBlockedSuccess',
    userUnblockedSuccess: 'userUnblockedSuccess',
    userReactivatedSuccess: 'userReactivatedSuccess',
    vehicleCreated: 'vehicleCreated',
    vehicleUpdatedSuccess: 'vehicleUpdatedSuccess',
    vehicleDisabledSuccess: 'vehicleDisabledSuccess',
    vehicleEnabledSuccess: 'vehicleEnabledSuccess',
    passwordRequired: 'passwordRequired',
    passwordMinLength: 'passwordMinLength',
    invalidPassword: 'invalidPassword',
    incorrectEmailOrPassword: 'incorrectEmailOrPassword',
    accountDeleted: 'accountDeleted',
    accountBlocked: 'accountBlocked',
    /** BE */
    /** validation */
    firstNameRequired: 'firstNameRequired',
    lastNameRequired: 'lastNameRequired',
    firstNameMinLength: 'firstNameMinLength',
    firstNameMaxLength: 'firstNameMaxLength',
    firstNameCapitalize: 'firstNameCapitalize',
    lastNameMinLength: 'lastNameMinLength',
    lastNameMaxLength: 'lastNameMaxLength',
    lastNameCapitalize: 'lastNameCapitalize',
    emailRequired: 'emailRequired',
    invalidEmail: 'invalidEmail',
    sendTypeRequired: 'sendTypeRequired',
    notAllowedValues: 'notAllowedValues',
    required: 'required',
    requiredField: 'requiredField',
    invalidField: 'invalidField',
    phoneRequired: 'phoneRequired',
    businessTypeRequired: 'businessTypeRequired',
    businessNameRequired: 'businessNameRequired',
    businessNameMinLength: 'businessNameMinLength',
    businessNameMaxLength: 'businessNameMaxLength',
    businessNameCapitalize: 'businessNameCapitalize',
    addressRequired: 'addressRequired',
    locationRequired: 'locationRequired',
    locationInvalid: 'locationInvalid',
    accountExist: 'accountExist',
    countryRequired: 'countryRequired',
    pinApprovalRequestDenied: 'pinApprovalRequestDenied',
    pinSendToPhone: 'pinSendToPhone',
    pinSendToEmail: 'pinSendToEmail',
    shiftNotStartedYet: 'shiftNotStartedYet',
    pinSendInvalidPhoneNumber: 'pinSendInvalidPhoneNumber',
    pinSendSuccess: 'pinSendSuccess',
    accountNotVerified: 'accountNotVerified',
    /** validation */

    up: 'up',
    left: 'left',
    right: 'right',
    down: 'down',
    clearOrderData: 'clearOrderData',
    calculateOrderData: 'calculateOrderData',
    invalidData: 'invalidData',
    versionCheckerTitle: 'versionCheckerTitle',
    versionCheckerText: 'versionCheckerText',
    reload: 'reload',
    emptyTable: 'emptyTable',
    emptyTableFilter: 'emptyTableFilter',
    controlTableTitle: 'controlTableTitle',
    emailTaken: 'emailTaken',
    next: 'next',
    send: 'send',
    go: 'go',
    status: 'status',
    start: 'start',
    phone: 'phone',
    category: 'category',
    name: 'name',
    description: 'description',
    price: 'price',
    free: 'free',
    is18Plus: 'is18Plus',
    driver: 'driver',
    restaurant: 'restaurant',
    fastFood: 'fastFood',
    tobacconist: 'tobacconist',
    market: 'market',
    shop: 'shop',
    other: 'other',
    search: 'search',
    emailLabel: 'emailLabel',
    firstNameLabel: 'firstNameLabel',
    lastNameLabel: 'lastNameLabel',
    tabUsers: 'tabUsers',
    tabDrivers: 'tabDrivers',
    tabRestaurants: 'tabRestaurants',
    loadingMessage: 'loadingMessage',
    country: 'country',
    phoneLabel: 'phoneLabel',
    active: 'active',
    available: 'available',
    unavailable: 'unavailable',
    notVerified: 'notVerified',
    pause: 'pause',
    _pause: '_pause',
    resume: 'resume',
    stop: 'stop',
    save: 'save',
    aborted: 'aborted',
    letStart: 'letStart',
    abort: 'abort',
    scheduled: 'scheduled',
    cancel: 'cancel',
    inactive: 'inactive',
    inactiveCategory: 'inactiveCategory',
    activeCategory: 'activeCategory',
    vehicleActive: 'vehicleActive',
    vehicleInactive: 'vehicleInactive',
    vehicle: 'vehicle',
    amount: 'amount',
    time: 'time',
    add: 'add',
    update: 'update',
    preview: 'preview',
    expense: 'expense',
    earnings: 'earnings',
    earnings_: 'earnings_',
    end: 'end',
    difference: 'difference',
    driverEnd: 'driverEnd',
    driverTempEnd: 'driverTempEnd',
    driverTempCanResume: 'driverTempCanResume',
    driverTempNotWork: 'driverTempNotWork',
    driverStart: 'driverStart',
    driverNotStarted: 'driverNotStarted',
    create: 'create',
    addImages: 'addImages',
    selectAll: 'selectAll',
    undo: 'undo',


    addText: 'addText',
    blocked: 'blocked',
    blockText: 'blockText',
    editText: 'editText',
    unblockText: 'unblockText',
    deleteText: 'deleteText',
    activateText: 'activateText',
    businessTypeLabel: 'businessTypeLabel',
    businessNameLabel: 'businessNameLabel',
    notificationsLabel: 'notificationsLabel',
    yes: 'yes',
    no: 'no',

    approvalTypeResetPin: 'approvalTypeResetPin',
    approvalTypeResetPinText: 'approvalTypeResetPinText',

    notFoundTitle: 'notFoundTitle',
    notFoundText: 'notFoundText',
    notFoundButtonLabel: 'notFoundButtonLabel',

    emailExist: 'emailExist',

    userBlockModalTitle: 'userBlockModalTitle',
    userBlockModalText: 'userBlockModalText',
    userUnblockModalTitle: 'userUnblockModalTitle',
    userUnblockModalText: 'userUnblockModalText',
    userInactiveModalTitle: 'userInactiveModalTitle',
    userInactiveModalText: 'userInactiveModalText',
    userActiveModalTitle: 'userActiveModalTitle',
    userActiveModalText: 'userActiveModalText',

    driverAddButtonTitle: 'driverAddButtonTitle',
    driverRegistrationTitle: 'driverRegistrationTitle',
    driverRegFormSendToOptionTitle: 'driverRegFormSendToOptionTitle',
    driverRegSendPhoneTitle: 'driverRegSendPhoneTitle',
    driverRegSendEmailTitle: 'driverRegSendEmailTitle',
    driverInactiveModalTitle: 'driverInactiveModalTitle',
    driverInactiveModalText: 'driverInactiveModalText',
    driverBlockModalTitle: 'driverBlockModalTitle',
    driverBlockModalText: 'driverBlockModalText',
    driverUnblockModalTitle: 'driverUnblockModalTitle',
    driverUnblockModalText: 'driverUnblockModalText',
    driverEditModalTitle: 'driverEditModalTitle',
    driverEditModalText: 'driverEditModalText',
    driverActiveModalTitle: 'driverActiveModalTitle',
    driverActiveModalText: 'driverActiveModalText',


    restaurantBlockModalTitle: 'restaurantBlockModalTitle',
    restaurantBlockModalText: 'restaurantBlockModalText',
    restaurantUnblockModalTitle: 'restaurantUnblockModalTitle',
    restaurantUnblockModalText: 'restaurantUnblockModalText',
    restaurantInactiveModalTitle: 'restaurantInactiveModalTitle',
    restaurantInactiveModalText: 'restaurantInactiveModalText',
    restaurantActiveModalTitle: 'restaurantActiveModalTitle',
    restaurantActiveModalText: 'restaurantActiveModalText',
    restaurantRegistrationTitle: 'restaurantRegistrationTitle',
    restaurantLocationTitle: 'restaurantLocationTitle',
    restaurantRegFormSendToOptionTitle: 'restaurantRegFormSendToOptionTitle',
    restaurantRegSendPhoneTitle: 'restaurantRegSendPhoneTitle',
    restaurantRegSendEmailTitle: 'restaurantRegSendEmailTitle',

    restaurantEditFormTitle: 'restaurantEditFormTitle',
    restaurantEditPickerOptDataLabel: 'restaurantEditPickerOptDataLabel',
    restaurantEditPickerOptMenuLabel: 'restaurantEditPickerOptMenuLabel',
    restaurantEditDataFormTitle: 'restaurantEditDataFormTitle',

    vehicleAddButtonTitle: 'vehicleAddButtonTitle',
    vehicleTypeCar: 'vehicleTypeCar',
    vehicleTypeMotor: 'vehicleTypeMotor',
    vehicleTypeBicycle: 'vehicleTypeBicycle',
    vehicleAddModalTitle: 'vehicleAddModalTitle',
    vehicleName: 'vehicleName',
    vehicleYear: 'vehicleYear',
    vehicleRegistrationPlate: 'vehicleRegistrationPlate',
    vehicleOwner: 'vehicleOwner',
    vehicleType: 'vehicleType',
    vehicleNumber: 'vehicleNumber',
    vehicleNotExist: 'vehicleNotExist',
    vehicleNotAvailable: 'vehicleNotAvailable',
    vehicleNameRequired: 'vehicleNameRequired',
    vehicleNameMinLength: 'vehicleNameMinLength',
    vehicleNameMaxLength: 'vehicleNameMaxLength',
    vehicleYearRequired: 'vehicleYearRequired',
    vehicleYearLength: 'vehicleYearLength',
    vehicleYearNumber: 'vehicleYearNumber',
    vehicleRegistrationPlateRequired: 'vehicleRegistrationPlateRequired',
    vehicleRegistrationPlateMinLength: 'vehicleRegistrationPlateMinLength',
    vehicleRegistrationPlateValidChar: 'vehicleRegistrationPlateValidChar',
    vehicleOwnerRequired: 'vehicleOwnerRequired',
    vehicleTypeRequired: 'vehicleTypeRequired',
    vehicleNumberRequired: 'vehicleNumberRequired',
    vehicleNumberMinLength: 'vehicleNumberMinLength',
    vehicleNumberOnlyNumber: 'vehicleNumberOnlyNumber',
    vehicleImageRequired: 'vehicleImageRequired',
    vehicleInactiveModalTitle: 'vehicleInactiveModalTitle',
    vehicleActiveModalTitle: 'vehicleActiveModalTitle',
    vehicleDisabledModalTitle: 'vehicleDisabledModalTitle',
    vehicleEnableModalTitle: 'vehicleEnableModalTitle',
    vehicleInactiveModalText: 'vehicleInactiveModalText',
    vehicleActiveModalText: 'vehicleActiveModalText',
    vehicleDisabledModalText: 'vehicleDisabledModalText',
    vehicleEnabledModalText: 'vehicleEnabledModalText',
    vehicleRemovedSuccess: 'vehicleRemovedSuccess',
    vehicleReactivatedSuccess: 'vehicleReactivatedSuccess',
    vehicleEditModalTitle: 'vehicleEditModalTitle',


    orderBiddingTimeMinMaxLength: 'orderBiddingTimeMinMaxLength',
    orderBiddingTimeInvalid: 'orderBiddingTimeInvalid',
    orderBiddingIsAlreadyCreated: 'orderBiddingIsAlreadyCreated',
    orderBiddingSuccessCreated: 'orderBiddingSuccessCreated',
    orderBiddingNotExist: 'orderBiddingNotExist',
    orderBidNotExist: 'orderBidNotExist',
    orderBidAlreadyAdded: 'orderBidAlreadyAdded',
    orderBidSuccessCreated: 'orderBidSuccessCreated',


    restaurantMenuItemAddButtonTitle: 'restaurantMenuItemAddButtonTitle',
    restaurantMenuTitle: 'restaurantMenuTitle',
    restaurantMenuInsertTypeTitle: 'restaurantMenuInsertTypeTitle',
    restaurantMenuInsertTypeExcel: 'restaurantMenuInsertTypeExcel',
    restaurantMenuInsertTypeManually: 'restaurantMenuInsertTypeManually',
    menuImportTypeRequired: 'menuImportTypeRequired',
    restaurantMenuInsertTypeExcelTitle: 'restaurantMenuInsertTypeExcelTitle',
    restaurantMenuInsertProgramTypeLabel: 'restaurantMenuInsertProgramTypeLabel',
    restaurantMenuUploadExcelButtonLabel: 'restaurantMenuUploadExcelButtonLabel',
    drink: 'drink',
    dishes: 'dishes',
    addition: 'addition',
    restaurantMenuItemType: 'restaurantMenuItemType',
    restaurantMenuItemCategory: 'restaurantMenuItemCategory',
    restaurantMenuItemName: 'restaurantMenuItemName',
    restaurantMenuItemDescription: 'restaurantMenuItemDescription',
    restaurantMenuItemAmount: 'restaurantMenuItemAmount',
    restaurantMenuItemPrice: 'restaurantMenuItemPrice',
    restaurantMenuItemPosition: 'restaurantMenuItemPosition',
    restaurantMenuItemIsFree: 'restaurantMenuItemIsFree',
    restaurantMenuItemIs18Plus: 'restaurantMenuItemIs18Plus',
    reset: 'reset',
    back: 'back',
    restaurantMenuResetDialogTitle: 'restaurantMenuResetDialogTitle',
    restaurantMenuResetDialogText: 'restaurantMenuResetDialogText',
    restaurantMenuFinishDialogTitle: 'restaurantMenuFinishDialogTitle',
    restaurantMenuFinishDialogText: 'restaurantMenuFinishDialogText',
    restaurantMenuGoBackDialogTitle: 'restaurantMenuGoBackDialogTitle',
    restaurantMenuGoBackDialogText: 'restaurantMenuGoBackDialogText',

    restaurantMenuItemInsertTitle: 'restaurantMenuItemInsertTitle',
    restaurantMenuItemInactiveModalTitle: 'restaurantMenuItemInactiveModalTitle',
    restaurantMenuItemInactiveModalText: 'restaurantMenuItemInactiveModalText',
    restaurantMenuItemActiveModalTitle: 'restaurantMenuItemActiveModalTitle',
    restaurantMenuItemActiveModalText: 'restaurantMenuItemActiveModalText',
    restaurantMenuTypeFilterLabel: 'restaurantMenuTypeFilterLabel',
    restaurantMenuCategoryFilterLabel: 'restaurantMenuCategoryFilterLabel',
    restaurantMenuStatusFilterLabel: 'restaurantMenuStatusFilterLabel',

    restaurantMenuNotExist: 'restaurantMenuNotExist',
    restaurantMenuItemsInvalid: 'restaurantMenuItemsInvalid',
    restaurantMenuItemsCategoryInvalid: 'restaurantMenuItemsCategoryInvalid',
    restaurantMenuItemsTypeInvalid: 'restaurantMenuItemsTypeInvalid',
    restaurantMenuItemsNameInvalid: 'restaurantMenuItemsNameInvalid',
    restaurantMenuItemsDescriptionInvalid: 'restaurantMenuItemsDescriptionInvalid',
    restaurantMenuItemsQtyInvalid: 'restaurantMenuItemsQtyInvalid',
    restaurantMenuItemsPriceInvalid: 'restaurantMenuItemsPriceInvalid',
    restaurantMenuItemsPositionInvalid: 'restaurantMenuItemsPositionInvalid',
    restaurantMenuItemCategoryNotExist: 'restaurantMenuItemCategoryNotExist',
    restaurantMenuItemCreatedSuccessfully: 'restaurantMenuItemCreatedSuccessfully',
    restaurantMenuItemUpdatedSuccessfully: 'restaurantMenuItemUpdatedSuccessfully',
    restaurantMenuItemDeletedSuccessfully: 'restaurantMenuItemDeletedSuccessfully',
    restaurantMenuCreatedSuccessfully: 'restaurantMenuCreatedSuccessfully',
    restaurantMenuItemNotExists: 'restaurantMenuItemNotExists',
    restaurantMenuDeletedSuccessfully: 'restaurantMenuDeletedSuccessfully',
    restaurantMenuReactivatedSuccessfully: 'restaurantMenuReactivatedSuccessfully',
    restaurantMenuItemReactivatedSuccessfully: 'restaurantMenuItemReactivatedSuccessfully',

    restaurantMenuTableEmpty: 'restaurantMenuTableEmpty',
    restaurantCategorySectionLabel: 'restaurantCategorySectionLabel',
    restaurantCategorySectionDefineTitle: 'restaurantCategorySectionDefineTitle',
    restaurantCategoryActiveModalTitle: 'restaurantCategoryActiveModalTitle',
    restaurantCategoryActiveModalText: 'restaurantCategoryActiveModalText',
    restaurantCategoryInactiveModalTitle: 'restaurantCategoryInactiveModalTitle',
    restaurantCategoryInactiveModalText: 'restaurantCategoryInactiveModalText',
    restaurantCategoryCreatedSuccessfully: 'restaurantCategoryCreatedSuccessfully',
    restaurantCategoryUpdatedSuccessfully: 'restaurantCategoryUpdatedSuccessfully',
    restaurantCategoryDeletedSuccessfully: 'restaurantCategoryDeletedSuccessfully',
    restaurantCategoryReactivatedSuccessfully: 'restaurantCategoryReactivatedSuccessfully',
    restaurantCategoryExist: 'restaurantCategoryExist',
    restaurantCategoryNotExist: 'restaurantCategoryNotExist',
    restaurantCategoryIs18PlusInvalid: 'restaurantCategoryIs18PlusInvalid',



    schedulingPageTitle: 'schedulingPageTitle',
    defineShiftForDayTitle: 'defineShiftForDayTitle',
    defineShiftsEmptyItems: 'defineShiftsEmptyItems',
    schedulingFirstShift: 'schedulingFirstShift',
    schedulingSecondShift: 'schedulingSecondShift',
    schedulingThirdShift: 'schedulingThirdShift',
    schedulingBoardTableHeaderWeekDays: 'schedulingBoardTableHeaderWeekDays',
    schedulingBoardTableHeaderFirstShift: 'schedulingBoardTableHeaderFirstShift',
    schedulingBoardTableHeaderSecondShift: 'schedulingBoardTableHeaderSecondShift',
    schedulingBoardTableHeaderThirdShift: 'schedulingBoardTableHeaderThirdShift',
    schedulingShiftsNotAdded: 'schedulingShiftsNotAdded',
    shiftSchedulingCreateInvalidVehicle: 'shiftSchedulingCreateInvalidVehicle',
    shiftSchedulingHandoverSuccess: 'shiftSchedulingHandoverSuccess',

    shiftSchedulingCreateInvalidVehicleDriver: 'shiftSchedulingCreateInvalidVehicleDriver',
    shiftSchedulingNotExist: 'shiftSchedulingNotExist',
    shiftAlreadyStart: 'shiftAlreadyStart',
    shiftSchedulingByDateCreated: 'shiftSchedulingByDateCreated',
    shiftSchedulingByShiftCreated: 'shiftSchedulingByShiftCreated',
    shiftSchedulingUpdated: 'shiftSchedulingUpdated',
    shiftSchedulingRemoved: 'shiftSchedulingRemoved',
    shiftSchedulingMaxShiftDate: 'shiftSchedulingMaxShiftDate',
    shiftSchedulingCanNotCreateAfterStart: 'shiftSchedulingCanNotCreateAfterStart',
    shiftSchedulingTempStop: 'shiftSchedulingTempStop',
    shiftSchedulingTempStopSuccess: 'shiftSchedulingTempStopSuccess',
    shiftSchedulingCanResumeSuccess: 'shiftSchedulingCanResumeSuccess',
    shiftSchedulingAlreadyTempStopped: 'shiftSchedulingAlreadyTempStopped',
    shiftSchedulingAlreadyTempCanResumed: 'shiftSchedulingAlreadyTempCanResumed',
    shiftSchedulingEndSuccess: 'shiftSchedulingEndSuccess',
    shiftSchedulingDriverBusy: 'shiftSchedulingDriverBusy',

    shiftDriverStartInvalidTime: 'shiftDriverStartInvalidTime',
    shiftDriverAlreadyStarted: 'shiftDriverAlreadyStarted',
    shiftDriverAlreadyEnded: 'shiftDriverAlreadyEnded',
    shiftDriverAlreadyPaused: 'shiftDriverAlreadyPaused',
    shiftDriverAlreadyResumed: 'shiftDriverAlreadyResumed',
    shiftDriverNotStarted: 'shiftDriverNotStarted',
    shiftDriverUnableToResume: 'shiftDriverUnableToResume',


    shiftSchedulingTempStopTitle: 'shiftSchedulingTempStopTitle',
    shiftSchedulingTempStopText: 'shiftSchedulingTempStopText',
    shiftSchedulingTempStopDriverText: 'shiftSchedulingTempStopDriverText',
    shiftSchedulingTempStopVehicleText: 'shiftSchedulingTempStopVehicleText',


    shiftSchedulingTempCanResumeTitle: 'shiftSchedulingTempCanResumeTitle',
    shiftSchedulingTempCanResumeText: 'shiftSchedulingTempCanResumeText',
    shiftSchedulingTempCanResumeDriverText: 'shiftSchedulingTempCanResumeDriverText',
    shiftSchedulingTempCanResumeVehicleText: 'shiftSchedulingTempCanResumeVehicleText',
    shiftSchedulingFinishText: 'shiftSchedulingFinishText',



    shiftSchedulingStopTitle: 'shiftSchedulingStopTitle',
    shiftSchedulingStopText: 'shiftSchedulingStopText',
    shiftSchedulingStopDriverText: 'shiftSchedulingStopDriverText',
    shiftSchedulingStopVehicleText: 'shiftSchedulingStopVehicleText',



    settingsAlreadyExists: 'settingsAlreadyExists',
    settingsNotExist: 'settingsNotExist',
    settingsKeyAlreadyExists: 'settingsKeyAlreadyExists',
    settingsCreated: 'settingsCreated',
    settingsUpdated: 'settingsUpdated',
    settingsRemoved: 'settingsRemoved',
    settingsReactivated: 'settingsReactivated',

    settingsPageTitle: 'settingsPageTitle',
    settingsShiftSchedulingLabel: 'settingsShiftSchedulingLabel',
    settingsOrdersLabel: 'settingsOrdersLabel',
    settingsCalculationLabel: 'settingsCalculationLabel',
    settingsShiftVehicleStartAmountLabel: 'settingsShiftVehicleStartAmountLabel',
    settingsShiftFirstShiftLabel: 'settingsShiftFirstShiftLabel',
    settingsShiftSecondShiftLabel: 'settingsShiftSecondShiftLabel',
    settingsShiftThirdShiftLabel: 'settingsShiftThirdShiftLabel',
    settingsShiftChangeShiftStartTitle: 'settingsShiftChangeShiftStartTitle',
    settingsShiftStartLabel: 'settingsShiftStartLabel',
    settingsShiftEndLabel: 'settingsShiftEndLabel',

    settingsOrderBiddingLabel: 'settingsOrderBiddingLabel',
    settingsOrderBiddingTimeLabel: 'settingsOrderBiddingTimeLabel',
    settingsOrderBiddingTimeTitle: 'settingsOrderBiddingTimeTitle',
    settingsOrderBiddingAutoStartTimeLabel: 'settingsOrderBiddingAutoStartTimeLabel',
    settingsOrderBiddingAutoStartTimeTitle: 'settingsOrderBiddingAutoStartTimeTitle',
    settingsOrderScheduledPendingTimeLabel: 'settingsOrderScheduledPendingTimeLabel',
    settingsOrderScheduledPendingTimeTitle: 'settingsOrderScheduledPendingTimeTitle',
    settingsTimeLabel: 'settingsTimeLabel',


    settingsOrderNoAddress: 'settingsOrderNoAddress',
    settingsOrderStopPriceNoAddress: 'settingsOrderStopPriceNoAddress',
    settingsOrderPurchaseStopPriceNoAddress: 'settingsOrderPurchaseStopPriceNoAddress',
    settingsOrderDistanceStartCalculation: 'settingsOrderDistanceStartCalculation',
    settingsOrderZone1: 'settingsOrderZone1',
    settingsOrderZone2: 'settingsOrderZone2',
    settingsOrderZone3: 'settingsOrderZone3',
    startPrice: 'startPrice',
    startKmPrice: 'startKmPrice',
    itemPrice: 'itemPrice',
    purchaseRangePrice: 'purchaseRangePrice',
    fromPrice: 'fromPrice',
    toPrice: 'toPrice',
    increaseRadius: 'increaseRadius',
    decreaseRadius: 'decreaseRadius',
    settingsOrderZoneChangeCenter: 'settingsOrderZoneChangeCenter',


    settingsOrderStopPriceChangeTitle: 'settingsOrderStopPriceChangeTitle',
    settingsOrderPurchaseStopPriceChangeTitle: 'settingsOrderPurchaseStopPriceChangeTitle',
    settingsOrderCenterPositionChangeTitle: 'settingsOrderCenterPositionChangeTitle',


    settingsOrderDistanceStartCalculationChangeTitle: 'settingsOrderDistanceStartCalculationChangeTitle',


    /** ORDERS */
    homePageTitle: 'homePageTitle',
    homeOrderAddButtonTitle: 'homeOrderAddButtonTitle',
    defineOrderModalTitle: 'defineOrderModalTitle',
    defineOrderSectionCustomerTitle: 'defineOrderSectionCustomerTitle',
    defineOrderCustomerNameLabel: 'defineOrderCustomerNameLabel',
    defineOrderCustomerPhoneLabel: 'defineOrderCustomerPhoneLabel',
    defineOrderCustomerPhoneInvalid: 'defineOrderCustomerPhoneInvalid',
    defineOrderCustomerAddressLabel: 'defineOrderCustomerAddressLabel',
    defineOrderCustomerBuildingNumberLabel: 'defineOrderCustomerBuildingNumberLabel',
    defineOrderSectionItemsTitle: 'defineOrderSectionItemsTitle',
    defineOrderSectionItemsEmpty: 'defineOrderSectionItemsEmpty',
    defineOrderItemLocationName: 'defineOrderItemLocationName',
    defineOrderItemDescription: 'defineOrderItemDescription',
    defineOrderItemIsPurchase: 'defineOrderItemIsPurchase',
    defineOrderItemAddress: 'defineOrderItemAddress',
    defineOrderItemPickUpValue: 'defineOrderItemPickUpValue',
    defineOrderItemPickUpTimeValue: 'defineOrderItemPickUpTimeValue',
    defineOrderItemPickUpValueUseTime: 'defineOrderItemPickUpValueUseTime',
    defineOrderItemPickUpValueUseValue: 'defineOrderItemPickUpValueUseValue',
    defineOrderItemAmount: 'defineOrderItemAmount',
    defineOrderSectionFooterTitle: 'defineOrderSectionFooterTitle',
    defineOrderFooterDriver: 'defineOrderFooterDriver',
    defineOrderFooterDeliveryAmount: 'defineOrderFooterDeliveryAmount',
    defineOrderFooterOrderDate: 'defineOrderFooterOrderDate',
    defineOrderFooterIsScheduled: 'defineOrderFooterIsScheduled',
    defineOrderFooterOrderTime: 'defineOrderFooterOrderTime',
    orderDeliveryAmountStartPrice: 'orderDeliveryAmountStartPrice',
    orderDeliveryAmountStartPriceKm: 'orderDeliveryAmountStartPriceKm',
    orderDeliveryAmountStopPrice: 'orderDeliveryAmountStopPrice',
    orderDeliveryAmountStopNoAddress: 'orderDeliveryAmountStopNoAddress',
    orderDeliveryAmountPurchaseNoAddress: 'orderDeliveryAmountPurchaseNoAddress',
    orderDeliveryAmountPurchase: 'orderDeliveryAmountPurchase',
    orderDeliveryAmountPurchaseDistance: 'orderDeliveryAmountPurchaseDistance',
    orderDeliveryAmountPurchaseFurthestPoint: 'orderDeliveryAmountPurchaseFurthestPoint',



    invalidOrderDate: 'invalidOrderDate',
    invalidOrderTime: 'invalidOrderTime',
    invalidOrderAmount: 'invalidOrderAmount',
    invalidOrderDeliveryAmount: 'invalidOrderDeliveryAmount',
    invalidOrderItemDescription: 'invalidOrderItemDescriptiom',
    invalidOrderItemAddress: 'invalidOrderItemAddress',
    invalidOrderItemAmount: 'invalidOrderItemAmount',
    invalidOrderItemPickUpValue: 'invalidOrderItemPickUpValue',
    orderFreeSuccessCreated: 'orderFreeSuccessCreated',
    orderFreeItemSuccessCreated: 'orderFreeItemSuccessCreated',
    orderFreeItemUpdateAmountSuccess: 'orderFreeItemUpdateAmountSuccess',
    orderFreeItemUpdatePickUpValueSuccess: 'orderFreeItemUpdatePickUpValueSuccess',
    orderFreeItemRemoveSuccess: 'orderFreeItemRemoveSuccess',
    orderFreeNotExist: 'orderFreeNotExist',
    orderNotBelongsToYou: 'orderNotBelongsToYou',
    invalidOrderFChangePriceAmount: 'invalidOrderFChangePriceAmount',
    orderFreeAlreadyAssigned: 'orderFreeAlreadyAssigned',
    orderFreePriceAmountChangedSuccess: 'orderFreePriceAmountChangedSuccess',
    orderFreeDriverAssignSuccess: 'orderFreeDriverAssignSuccess',
    orderFreeItemNoteMinLength: 'orderFreeItemNoteMinLength',
    orderFreeItemFinishSuccess: 'orderFreeItemFinishSuccess',
    orderFreeItemNotExist: 'orderFreeItemNotExist',
    orderFreeItemAlreadyCompleted: 'orderFreeItemAlreadyCompleted',
    orderFreeAlreadyCompleted: 'orderFreeAlreadyCompleted',
    orderFreeIsRejected: 'orderFreeIsRejected',
    orderFreeIsArchived: 'orderFreeIsArchived',
    orderFreeInvalidAction: 'orderFreeInvalidAction',
    orderFreeItemsNeedToBeCompleted: 'orderFreeItemsNeedToBeCompleted',
    orderFreeAbortSuccess: 'orderFreeAbortSuccess',
    orderFreeSetToPendingSuccess: 'orderFreeSetToPendingSuccess',
    orderFreeSetActive: 'orderFreeSetActive',
    orderFreeCustomerNotShowSuccess: 'orderFreeCustomerNotShowSuccess',
    orderFreeAlreadyAborted: 'orderFreeAlreadyAborted',
    orderFreeCoudtNotBeAborted: 'orderFreeCoudtNotBeAborted',
    orderFreeAlreadyCustomerNotShow: 'orderFreeAlreadyCustomerNotShow',
    orderFreeIsAlreadySaw: 'orderFreeIsAlreadySaw',

    orderViewFilterActiveText: 'orderViewFilterActiveText',
    orderViewFilterPendingText: 'orderViewFilterPendingText',
    orderViewFilterScheduledText: 'orderViewFilterScheduledText',
    orderViewFilterCompletedText: 'orderViewFilterCompletedText',
    orderViewFilterRejectedText: 'orderViewFilterRejectedText',
    orderViewDriverFilterFreeText: 'orderViewDriverFilterFreeText',
    orderViewFilterBusyText: 'orderViewFilterBusyText',
    orderViewFilterBreakText: 'orderViewFilterBreakText',
    orderViewTitle: 'orderViewTitle',
    orderDriverItemViewCurrentAmount: 'orderDriverItemViewCurrentAmount',
    orderDriverItemViewCurrentCompleted: 'orderDriverItemViewCurrentCompleted',
    orderDriverItemViewCurrentOrders: 'orderDriverItemViewCurrentOrders',
    orderDriverItemViewCurrentBided: 'orderDriverItemViewCurrentBided',

    orderViewsListActionAbort: 'orderViewsListActionAbort',
    orderViewsListActionNotShowUp: 'orderViewsListActionNotShowUp',
    orderViewsListActionAddStop: 'orderViewsListActionAddStop',
    orderViewsListActionEditInfo: 'orderViewsListActionEditInfo',
    orderViewsListActionEditPrice: 'orderViewsListActionEditPrice',
    orderViewsListActionSelectOrder: 'orderViewsListActionSelectOrder',
    orderViewsListDispatcherCreated: 'orderViewsListDispatcherCreated',
    orderViewsListDriverSaw: 'orderViewsListDriverSaw',
    orderViewsListActionSetToPending: 'orderViewsListActionSetToPending',
    orderViewsListActionSetBidding: 'orderViewsListActionSetBidding',
    orderViewsListActionPreview: 'orderViewsListActionPreview',
    orderViewsListActionActive: 'orderViewsListActionActive',

    orderSetToPendingTitle: 'orderSetToPendingTitle',
    orderSetToPendingText: 'orderSetToPendingText',
    orderMakeActiveTitle: 'orderMakeActiveTitle',
    orderMakeActiveText: 'orderMakeActiveText',

    orderCreateBiddingTitle: 'orderCreateBiddingTitle',
    orderCreateBiddingText: 'orderCreateBiddingText',
    orderCreateBiddingTimeLabel: 'orderCreateBiddingTimeLabel',

    orderViewAssignDriverAction: 'orderViewAssignDriverAction',
    orderViewAssignDriverDialogTitle: 'orderViewAssignDriverDialogTitle',
    orderViewAssignDriverDialogText: 'orderViewAssignDriverDialogText',


    orderViewAbortDialogTitle: 'orderViewAbortDialogTitle',
    orderViewAbortDialogButtonLabel: 'orderViewAbortDialogButtonLabel',
    orderViewAbortNoteLabel: 'orderViewAbortNoteLabel',

    orderDriverListActionAssigned: 'orderDriverListActionAssigned',

    orderViewChangeDeliveryAmountTitle: 'orderViewChangeDeliveryAmountTitle',
    changeOrderDeliveryAmountLabel: 'changeOrderDeliveryAmountLabel',
    orderViewAddItemTitle: 'orderViewAddItemTitle',
    orderViewUpdateInformationTitle: 'orderViewUpdateInformationTitle',
    orderFreeUpdateInformationSuccess: 'orderFreeUpdateInformationSuccess',
    destination: 'destination',

    orderPreviewIsPurchaseTitle: 'orderPreviewIsPurchaseTitle',
    orderPreviewDialogTitle: 'orderPreviewDialogTitle',
    orderPreviewDialogOrderAmount: 'orderPreviewDialogOrderAmount',
    orderPreviewItemsHeader: 'orderPreviewItemsHeader',
    orderPreviewItemsPickUpTime: 'orderPreviewItemsPickUpTime',
    orderPreviewItemsCompletedText: 'orderPreviewItemsCompletedText',
    orderPreviewOrderAmount: 'orderPreviewOrderAmount',
    orderPreviewDeliveryAmount: 'orderPreviewDeliveryAmount',
    orderPreviewTotalAmount: 'orderPreviewTotalAmount',
    orderPreviewItemsAmount: 'orderPreviewItemsAmount',
    orderPreviewOrderTime: 'orderPreviewOrderTime',
    orderPreviewItemsCompletedTime: 'orderPreviewItemsCompletedTime',
    orderPreviewItemsFinishInformation: 'orderPreviewItemsFinishInformation',
    orderPreviewOrderAbortTimeLabel: 'orderPreviewOrderAbortTimeLabel',
    orderPreviewOrderAbortNoteLabel: 'orderPreviewOrderAbortNoteLabel',
    orderPreviewUserTextLabel: 'orderPreviewUserTextLabel',


    orderPreviewItemsPickUpTimeEditTitle: 'orderPreviewItemsPickUpTimeEditTitle',
    orderPreviewItemsPriceEditTitle: 'orderPreviewItemsPriceEditTitle',
    orderPreviewItemsDescriptionEditTitle: 'orderPreviewItemsDescriptionEditTitle',
    orderPreviewItemsRemoveTitle: 'orderPreviewItemsRemoveTitle',


    orderPreviewBiddingTitle: 'orderPreviewBiddingTitle',
    orderPreviewItemsRemoveModalTitle: 'orderPreviewItemsRemoveModalTitle',
    orderPreviewItemRemoveModalText: 'orderPreviewItemRemoveModalText',


    notifications: 'notifications',
    notificationOrderFinishedTitle: 'notificationOrderFinishedTitle',
    notificationOrderItemFinishedTitle: 'notificationOrderItemFinishedTitle',
    notificationOrderAbortedTitle: 'notificationOrderAbortedTitle',
    notificationOrderCustomerNotShowTitle: 'notificationOrderCustomerNotShowTitle',
    notificationOrderFinishedText: 'notificationOrderFinishedText',
    notificationOrderItemFinishedText: 'notificationOrderItemFinishedText',
    notificationOrderAbortedText: 'notificationOrderAbortedText',
    notificationOrderCustomerNotShowText: 'notificationOrderCustomerNotShowText',
    notificationOrderCustomerNotShowFinishText: 'notificationOrderCustomerNotShowFinishText',
    notificationRemovedSuccessfully: 'notificationRemovedSuccessfully',
    notificationNothingToRemove: 'notificationNothingToRemove',
    notificationsClearTitle: 'notificationsClearTitle',
    notificationsClearSuccessfully: 'notificationsClearSuccessfully',
    notificationsClearText: 'notificationsClearText',
    notificationsClearSubText: 'notificationsClearSubText',

    notificationsBid: 'notificationsBid',
    notificationOrderBiddingFinishedText: 'notificationOrderBiddingFinishedText',
    notificationDriverOrderBidMadeText: 'notificationDriverOrderBidMadeText',

    orderProcurementButtonTitle: 'orderProcurementButtonTitle',
    orderProcurementButtonText: 'orderProcurementButtonText',
    orderProcurementsMenuTitle: 'orderProcurementsMenuTitle',
    orderProcurementMenuItemTitle: 'orderProcurementMenuItemTitle',
    orderProcurementMenuItemText: 'orderProcurementMenuItemText',
    orderProcurementMenuItemBuildingNumberText: 'orderProcurementMenuItemBuildingNumberText',
    orderProcurementMenuItemDateText: 'orderProcurementMenuItemDateText',
    orderProcurementAbortTitle: 'orderProcurementAbortTitle',
    orderProcurementAbortText: 'orderProcurementAbortText',
    orderProcurementAbortAddressText: 'orderProcurementAbortAddressText',
    orderProcurementAbortedSuccess: 'orderProcurementAbortedSuccess',

    userUpdateDataSuccess: 'userUpdateDataSuccess',
    orderProcurementRelatedItemsModalTitle: 'orderProcurementRelatedItemsModalTitle',
};
