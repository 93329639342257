import React from 'react';
import { EffectCoverflow, Pagination } from 'swiper/modules';
import {
    PreviewProcurementItemImagesSwiper,
    PreviewProcurementItemImagesSwiperSlide,
    PreviewProcurementItemImagesSwiperSlideImage
} from './style';

import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';

const PreviewProcurementItemImages = ({images}: any) => {

    console.log(images);

    if(!images || !images.length) return null;
    
    return (
        <>
            <PreviewProcurementItemImagesSwiper
                effect="coverflow"
                grabCursor={true}
                centeredSlides={true}
                slidesPerView="auto"
                coverflowEffect={{
                    rotate: 50,
                    stretch: 0,
                    depth: 100,
                    modifier: 1,
                    slideShadows: true,
                }}
                pagination={{ clickable: true }}
                modules={[EffectCoverflow, Pagination]}
            >
                {images?.length ? images.map((image: any) => (
                    <PreviewProcurementItemImagesSwiperSlide key={image.id}>
                        <PreviewProcurementItemImagesSwiperSlideImage src={image.image} alt={`${image.id}`} />
                    </PreviewProcurementItemImagesSwiperSlide>
                )) : null}
            </PreviewProcurementItemImagesSwiper>
        </>
    );
};

export default PreviewProcurementItemImages;