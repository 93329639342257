import React, { Suspense, useCallback, useContext, useEffect, useState } from 'react';
import {
    OrderFreeFormFrame,
    OrderFreeProcurementText,
    OrderFreeProcurementTextContainer,
    OrderFreeProcurementTitle
} from './style';
import useModalFormData from '../../../../../hooks/useModalFormData';
import { Translate } from '../../../../../internationalization/translate';
import { initialValuesOrderFree } from './validation';
import { Formik } from 'formik';
import * as yup from 'yup';
import OrderFreeContext from './context/context';
import useCreateOrderMutation from '../../../../../core/api/hooks/mutations/order/useCreateOrderMutation';
import UiDataContext from '../../../../../context/UiContext/context';
import { errorToast, successToast } from '../../../../../components/Utils/Toast';
import { useTranslation } from 'react-i18next';
import { isString, isEmpty, pick } from 'lodash';
import moment from 'moment-timezone';
import { initialOrderItemRecord } from './context/d';
import { useDispatch, useSelector } from 'react-redux';
import { selectShiftDrivers } from '../../../../../store/app/appSlice';
import {
    clearOrderProcurement,
    selectOrderImages,
    selectOrderProcurement, selectOrderSelectedImages,
    setOrderProcurementImages,
    setOrderProcurementSelectedImages
} from '../../../../../store/order/orderSlice';
import OrderFreeFormDefinition from './OrderFrom';
import { ORDER_TYPES } from '../../../d';
import useCreateOrderProcurementMutation
    from '../../../../../core/api/hooks/mutations/order/useCreateOrderProcurementMutation';
import useGetProcurementImages from '../../../../../core/api/hooks/query/procurement/useGetProcurementImages';
import { queryClient } from '../../../../../core/root/ReactQueryProvider';
import { sleep } from '../../../../../utils';

const DefineOrderFreeForm = ({ setShowForm }: any) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { setLoading, resetLoading } = useContext(UiDataContext);
    const { items, resetState, setFromStorage, setData } = useContext(OrderFreeContext);
    const orderProcurement = useSelector(selectOrderProcurement);
    const orderProcurementImages = useSelector(selectOrderImages);
    const orderProcurementSelectedImages = useSelector(selectOrderSelectedImages);
    const shiftDrivers = useSelector(selectShiftDrivers);

    const storageData = localStorage.getItem('ORDER_FORMIK');
    const parsedData = (() => {
        try {
            const dd = storageData ? JSON.parse(storageData) : null;
            return dd ? {
                ...dd,
                orderDate: dd?.orderDate ? new Date(dd.orderDate as any) : new Date()
            } : {};
        } catch (e) {
            return {} as any;
        }
    })();

    useEffect(() => {
        if (localStorage.getItem('ORDER_REMOVE')) {
            localStorage.removeItem('ORDER_REMOVE');
            setFromStorage(false);
        }
    }, []);

    const [initValues, setInitValues] = useState({
        orderDate: (() => {
            const _time = moment().tz('Europe/Belgrade').hours();
            return moment().tz('Europe/Belgrade').subtract(+_time >= 0 && +_time <= 2 ? 1 : 0, 'day');
        })(),
        ...initialValuesOrderFree,
        ...(Object.assign({}, !parsedData ? orderProcurement ? {
            customerName: orderProcurement?.account?.nickname || '',
            orderProcurementId: orderProcurement?.id,
            customerPhone: orderProcurement?.account?.phone?.split(orderProcurement?.account?.phoneCode.replace(/\+/gmi, ''))?.[1],
            address: orderProcurement.address,
            addressObject: orderProcurement.addressJson ? isString(orderProcurement.addressJson) ? JSON.parse(orderProcurement.addressJson) : orderProcurement.addressJson : null,
            customerBuildingNumber: orderProcurement?.buildingNumber || '',
        } : (Object.keys(initialOrderItemRecord) || []).reduce((acc: any, x: any) => {
            return {
                ...acc,
                [`1720895846915_${x}`]: ''
            };
        }, {}) : {}, !orderProcurement ? parsedData || {} : {}))
    });


    const getOrderProcurementSelectedImages = useCallback(() => {
        try {
            const dd = localStorage.getItem('ORDER_PROCUREMENT_SELECTED_IMAGES');
            const _imgs = localStorage.getItem('ORDER_PROCUREMENT_IMAGES');
            const selectedImages = dd ? JSON.parse(dd) : {};
            const images = _imgs ? JSON.parse(_imgs) : [];
            if(images?.length) dispatch(setOrderProcurementImages(images));
            if(!isEmpty(selectedImages)) dispatch(setOrderProcurementSelectedImages(selectedImages));
        }catch (e) {
            /** */
            console.log('error imgs ', e);
        }
    }, []);

    useEffect(() => {
        getOrderProcurementSelectedImages();
        if (!parsedData) return;
        const items = Object.keys(parsedData).filter((x: any) => /^\d/.test(x));
        if (!items.length) return;
        const itemsNew = Object.keys(parsedData)?.filter((x: any) => /^\d/.test(x))?.reduce((acc, item) => {
            const match = item.match(/^(\d+)_/); // Extract the number before the underscore
            if (match) {
                const uniqueNumber = match[1];
                const field = item.substring(match[0].length);
                if (!acc[uniqueNumber]) {
                    acc[uniqueNumber] = {};
                }
                acc[uniqueNumber] = {
                    id: uniqueNumber,
                    ...acc[uniqueNumber],
                    [field]: parsedData[`${uniqueNumber}_${field}`]
                };
            }
            return acc;
        }, {} as any);
        setData({
            items: Object.keys(itemsNew).map((x: any) => itemsNew[x])
        });
    }, []);

    const schemaDefault = yup.object().shape({
        customerPhone: yup.string().required(Translate.required),
        address: yup.string().required(Translate.required),
        orderDeliveryAmount: yup.string().required(Translate.required),
        orderDate: yup.string().required(Translate.required),
        orderProcurementId: yup.string().optional(),
        orderType: yup.string().optional()
    });
    const [newValidationSchema, setNewValidationSchema] = useState(schemaDefault);

    const createOrderMutation = useCreateOrderMutation();
    const createOrderProcurementMutation = useCreateOrderProcurementMutation();


    const { data } = useGetProcurementImages(orderProcurement?.id);

    useEffect(() => {
        if(!data || !data?.length) return;
        const images = data.reduce((acc: any, x: any) => {
            const dd = orderProcurementImages.find(i => i.id === x.id);
            return [
                ...acc,
                {...x, ...(dd || {})}
            ];
        }, [] as any);
        dispatch(setOrderProcurementImages([...images]));
    }, [data]);

    const goBack = useCallback(() => {
        setShowForm(false);
        resetState();
        dispatch(clearOrderProcurement());
    }, [setShowForm, resetState, dispatch]);

    useModalFormData({
        modalTitle: 'defineOrderModalTitle'
    });

    const onSubmit = useCallback(async (values: any, { setSubmitting, setTouched, resetForm }: any) => {
        setTouched(Object.keys(values).reduce((acc, x) => ({
            ...acc,
            [x]: true
        }), {}));
        if (!values) {
            return;
        }
        ;
        setSubmitting(true);
        try {
            setLoading();
            const _items = items.map((x: any) => {
                const pickUpValue = (() => {
                    const pickUpValue = values?.[`${x.id}_pickUpValueNum`] ? values?.[`${x.id}_pickUpValueNum`] :
                        values?.[`${x.id}_pickUpValueMask`] ? values?.[`${x.id}_pickUpValueMask`] : undefined;
                    if (/:/gmi.test(pickUpValue)) {
                        const arr = pickUpValue.split(':');
                        return `${Number(arr[0])}:${arr[1]}`;
                    }
                    return Number(pickUpValue);
                })();
                return {
                    tempId: x.id,
                    description: values[`${x.id}_description`],
                    address: values[`${x.id}_address`],
                    addressJson: values?.[`${x.id}_addressObject`],
                    isPurchase: Number(values?.[`${x.id}_isPurchase`]),
                    pickUpValue,
                    amount: !isNaN(Number(values?.[`${x.id}_amount`]?.replace(/\./gmi, '').replace(/,/gi, '.'))) ? Number(values?.[`${x.id}_amount`]?.replace(/\./gmi, '').replace(/,/gi, '.')) : void (0),
                    procurementImages: (orderProcurementSelectedImages?.[x.id] || [])?.map((image: any) => ({
                        id: image?.id,
                        image: image?.image
                    }))
                };
            });
            const objData = {
                ...(pick(values, ['customerName', 'customerPhone', 'customerBuildingNumber', 'customerAddress', 'customerAddressJson', 'orderProcurementId']) || {}),
                customerAddress: values.address,
                customerAddressJson: values.addressObject,
                isScheduled: values?.isScheduled,
                orderDate: moment(values?.orderDate).tz('Europe/Belgrade').format('YYYY-MM-DD'),
                orderDeliveryAmount: Number(values.orderDeliveryAmount?.replace(/\./gmi, '').replace(/,/gi, '.')),
                orderDeliveryAmountInfoCalculation: values?.orderDeliveryAmountInfoCalculation,
                orderTime: values?.orderTime ? moment(values?.orderTime).tz('Europe/Belgrade').format('HH:mm') : undefined,
                items: _items,
                shiftSchedulingId: shiftDrivers?.find((x: any) => x.driverId == values.driverId)?.shiftSchedulingId,
                driverId: values?.driverId ? values?.driverId : undefined,
                orderType: values?.orderProcurementId ? ORDER_TYPES.ORDER_PROCUREMENT : ORDER_TYPES.ORDER_FREE
            } as any;
            const result = !objData?.orderProcurementId ? await createOrderMutation.mutateAsync(objData) : await createOrderProcurementMutation.mutateAsync(objData);
            if (result?.status === 200) {
                successToast(t(result.message));
                await queryClient.invalidateQueries({ queryKey: ['get-orders'] });
                await queryClient.invalidateQueries({ queryKey: ['get-current-drivers'] });
                if (values.orderProcurementId) {
                    await queryClient.invalidateQueries({ queryKey: ['get-procurements-count'] });
                    await queryClient.invalidateQueries({ queryKey: ['get-procurements'] });
                }
                localStorage.setItem('ORDER_REMOVE', '1');
                await sleep(500);
                resetForm();
                goBack();
            }
        } catch (e: any) {
            errorToast(t(e?.response?.data?.key || e?.response?.data?.message));
        } finally {
            resetLoading();
            setSubmitting(false);
        }
    }, [createOrderMutation, createOrderProcurementMutation, setLoading, resetLoading, goBack, items, shiftDrivers, orderProcurementSelectedImages]);

    useEffect(() => {
        if (!items) return;
        let obj = {};
        const shiftsValidations = items.reduce((acc: any, x: any, index: number) => {
            const dd = Object.assign({
                [`${x.id}_description`]: yup.string().required(Translate.requiredField),
                [`${x.id}_pickUpValueNum`]: yup.number().nullable().test(`${x.id}_pickUpValue`, Translate.invalidField, (value) => value === undefined || value === null || value % 5 === 0)
            }, index === 0 ? {
                [`${x.id}_address`]: yup.string().required(Translate.requiredField)
            } : {});
            obj = {
                ...obj,
                ...dd
            };
            return acc.concat(
                yup.object().shape(dd));
        }, yup.object().shape({}));
        setNewValidationSchema(schemaDefault.concat(shiftsValidations as any));
        setInitValues({
            ...initialValuesOrderFree,
            orderDate: (() => {
                const _time = moment().tz('Europe/Belgrade').hours();
                console.log('time form init', moment().tz('Europe/Belgrade').subtract(+_time >= 0 && +_time <= 2 ? 1 : 0, 'day').format('YYYY-MM-DD'));
                return moment().tz('Europe/Belgrade').subtract(+_time >= 0 && +_time <= 2 ? 1 : 0, 'day').format('YYYY-MM-DD');
            })()
        });
    }, [items]);

    return (
        <OrderFreeFormFrame>
            {orderProcurement?.id && (<OrderFreeProcurementTextContainer>
                <OrderFreeProcurementTitle>Nabavka</OrderFreeProcurementTitle>
                <OrderFreeProcurementText>
                    {orderProcurement?.text}
                </OrderFreeProcurementText>
            </OrderFreeProcurementTextContainer>)}
            <Formik
                initialValues={initValues}
                validationSchema={newValidationSchema}
                onSubmit={onSubmit}
                validateOnBlur
                validateOnChange
            >
                <Suspense fallback={<div>Loading...</div>}>
                    <OrderFreeFormDefinition goBack={goBack} />
                </Suspense>
            </Formik>
        </OrderFreeFormFrame>
    );
};

export default DefineOrderFreeForm;