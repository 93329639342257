import React, { useCallback, useMemo } from 'react';
import { ORDER_AMOUNT_DELIVERY_TYPES } from '../../../../d';
import { multiply } from 'lodash';
import { Translate } from '../../../../../../internationalization/translate';
import { PopoverButtonStyled } from '../../../../../../components/Popover/style';
import { InfoOutlined } from '@mui/icons-material';
import { Divider, Popover } from '@mui/material';
import {
    OrderDeliveryAmountPopoverCloseButton,
    OrderDeliveryAmountPopoverContainer,
    OrderDeliveryAmountRenderLabelValue
} from '../style';
import ClearIcon from '@mui/icons-material/Clear';
import { formatCurrency } from '../../../../../../utils';

const OrderDeliveryAmountPopoverInfo = ({
                                            data: values,
                                            anchorOrigin = { vertical: 'top', horizontal: 'right' },
                                            transformOrigin = { vertical: 'bottom', horizontal: 'right' },
                                        }: any) => {
    const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);
    const open = useMemo(() => Boolean(anchorEl), [anchorEl]);
    const handleClick = useCallback((event: React.MouseEvent<HTMLDivElement>) => {
        setAnchorEl(event.currentTarget);
    }, [setAnchorEl]);

    const handleClose = useCallback(() => {
        setAnchorEl(null);
    }, [setAnchorEl]);

    const [items, sumarize, finalKm, furthestPoint] = useMemo(() => {
        return [
            (values?.orderDeliveryAmountInfoCalculation?.items || []).map((x: any) => ({
                ...x,
                price: x.value,
                value: (() => {
                    if (x.type === ORDER_AMOUNT_DELIVERY_TYPES.START_PRICE_KM) return multiply(x.kmToCalc, x.value);
                    return x.value;
                })(),
                label: (() => {
                    switch (x.type) {
                        case ORDER_AMOUNT_DELIVERY_TYPES.START_PRICE_KM:
                            return Translate.orderDeliveryAmountStartPriceKm;
                        case ORDER_AMOUNT_DELIVERY_TYPES.STOP_PRICE:
                            return Translate.orderDeliveryAmountStopPrice;
                        case ORDER_AMOUNT_DELIVERY_TYPES.STOP_PRICE_NO_ADDRESS:
                            return Translate.orderDeliveryAmountStopNoAddress;
                        case ORDER_AMOUNT_DELIVERY_TYPES.STOP_PRICE_PURCHASE_NO_ADDRESS:
                            return Translate.orderDeliveryAmountPurchaseNoAddress;
                        case ORDER_AMOUNT_DELIVERY_TYPES.STOP_PRICE_PURCHASE:
                            return Translate.orderDeliveryAmountPurchase;
                        default:
                            return Translate.orderDeliveryAmountStartPrice;
                    }
                })()
            })),
            values?.orderDeliveryAmount,
            values?.orderDeliveryAmountInfoCalculation?.km,
            values?.orderDeliveryAmountInfoCalculation?.furthestPoint
        ];
    }, [values]);

    return (
        <>
            <PopoverButtonStyled
                id="demo-customized-button"
                aria-controls={open ? 'demo-customized-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
            >
                <InfoOutlined />
            </PopoverButtonStyled>
            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                elevation={0}
                anchorOrigin={anchorOrigin}
                transformOrigin={transformOrigin}
            >
                <OrderDeliveryAmountPopoverContainer>
                    <OrderDeliveryAmountPopoverCloseButton onClick={handleClose}>
                        <ClearIcon />
                    </OrderDeliveryAmountPopoverCloseButton>
                    {items?.length ? items.map((x: any, index: number) => {
                        return <OrderDeliveryAmountRenderLabelValue label={x.label} value={x.value} key={index + 1}
                                                                    isRow format={formatCurrency} />;
                    }) : null}
                    {items?.length ? <Divider /> : null}
                    <OrderDeliveryAmountRenderLabelValue label={Translate.defineOrderFooterDeliveryAmount}
                                                         value={sumarize} isRow />

                    <OrderDeliveryAmountRenderLabelValue label={Translate.orderDeliveryAmountPurchaseDistance}
                                                         value={finalKm} isRow />
                    <OrderDeliveryAmountRenderLabelValue label={Translate.orderDeliveryAmountPurchaseFurthestPoint}
                                                         value={furthestPoint?.item?.address} isRow />
                </OrderDeliveryAmountPopoverContainer>
            </Popover>
        </>
    );
};

export default OrderDeliveryAmountPopoverInfo;