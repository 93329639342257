import styled from 'styled-components';
import { Flex } from '../../../../../../../assets/style';


export const PreviewOrderItemRemoveItemContainer = styled(Flex)`
    flex-direction: column;
    max-width: 450px;
    padding: 20px;
    justify-content: center;
    align-items: center;
    text-align: center;
`;