import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import {
    OrderProcurementListContent,
    OrderProcurementListHeader, OrderProcurementListHeaderCloseButton, OrderProcurementListHeaderTitle,
    OrderProcurementListHeaderTitleContainer, OrderProcurementsListContainer
} from './style';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import useGetProcurements from '../../../../../core/api/hooks/query/procurement/useGetProcurements';
import OrderProcurementListItem from '../OrderProcurementListItem';
import { Translate } from '../../../../../internationalization/translate';


const OrderProcurementList = ({ onClose, setRefetchCount, handleOpenOrder }: any) => {
    const {t} = useTranslation();

    const { data, isFetched, refetch } = useGetProcurements();

    const setRefetch = useCallback(()=> {
        refetch();
        setRefetchCount();
    },[refetch, setRefetchCount]);

    

    return (
        <OrderProcurementsListContainer>
            <OrderProcurementListHeader>
                <OrderProcurementListHeaderTitleContainer>
                    <OrderProcurementListHeaderTitle>{`${t(Translate.orderProcurementsMenuTitle)} (${data?.length || 0})`}</OrderProcurementListHeaderTitle>
                </OrderProcurementListHeaderTitleContainer>
                <OrderProcurementListHeaderCloseButton onClick={onClose}>
                    <CloseOutlinedIcon />
                </OrderProcurementListHeaderCloseButton>
            </OrderProcurementListHeader>
            <OrderProcurementListContent>
                {isFetched && !data?.length ?  <>Nema narbavki</> :(data || []).map((item: any, index: number) => <OrderProcurementListItem key={index} item={item} setRefetch={setRefetch} handleOpenOrder={handleOpenOrder} />)}
            </OrderProcurementListContent>
        </OrderProcurementsListContainer>
    );
};

export default OrderProcurementList;